import React from 'react';
import {
  FilterSvg,
  GuruzSvg,
  LocationSvg,
  SearchSvg,
  StarSvg,
  ViolenSvg,
} from '../../../assets/svg';
import './index.css';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';

export default function ExploreRequestsPosted({
  requirements,
  state,
  setState,
}) {
  return (
    <div className='selectedTab'>
      <div className='tabHeader'>
        <div className='tabTitle'>
          Posted <span>{requirements?.length ?? 0} Explore Requests</span>
        </div>
        {/* <div className='search'>
          <div className='searchInput'>
            <SearchSvg />
            <input type='text' name='Search' placeholder='Search' />
          </div>
          <div className='filter'>
            <FilterSvg />
            <button>Filter</button>
          </div>
        </div> */}
      </div>
      <dib className='tabBody'>
        <div className='requestCards'>
          {requirements?.length === 0
            ? 'No requests posted'
            : requirements?.map((requirement) => (
                <RequestCard
                  key={requirement.id}
                  requirement={requirement}
                  state={state}
                  setState={setState}
                />
              ))}
        </div>
      </dib>
    </div>
  );
}

const RequestCard = ({ state, setState, requirement }) => {
  return (
    <div key={requirement.id} className='requestCard'>
      <div className='requestCardHeader'>
        <div className='icon'>
          <ViolenSvg />
        </div>
        <div className='cardTitle'>
          Looking for {requirement.category[0]} Guru!
          <div className='location'>
            <LocationSvg />
            {requirement?.prefLocation?.fullAddress ?? '-'}
          </div>
        </div>
      </div>
      <div className='requestCardBody'>
        <div>
          <div>{requirement?.responseCount ?? 0}</div>
          <di className='responses'>Responses</di>
        </div>
        <div>
          <button onClick={() => setState({ isPaneOpen: true })}>
            View Detail
          </button>
        </div>
      </div>
      <ExploreRequestDetailSlider state={state} setState={setState} />
    </div>
  );
};

const ExploreRequestDetailSlider = ({ state, setState }) => {
  return (
    <SlidingPane
      className='requestSlider'
      overlayClassName='requestSliderOverlay'
      isOpen={state.isPaneOpen}
      hideHeader
      width='30%'
      onRequestClose={() => {
        // triggered on "<" on left top click or on outside click
        setState({ isPaneOpen: false });
      }}
    >
      <div className='requestCardHeader'>
        <div className='icon'>
          <ViolenSvg />
        </div>
        <div className='cardTitle'>
          Looking for Violin Guru!
          <div className='location'>
            <LocationSvg />
            Mountain View CA
          </div>
        </div>
      </div>
      <div className='responses'>25 Responses</div>
      <div className='requestDeatailCards'>
        {[1, 2, 3, 4, 5].map((e) => (
          <RequestDeatailCard key={e} />
        ))}
      </div>
    </SlidingPane>
  );
};

const RequestDeatailCard = ({ id }) => {
  return (
    <div key={id} className='requestDeatailCard'>
      <div className='requestDeatailCardHeader'>
        <div className='section'>
          <div className='icon'>
            <ViolenSvg />
          </div>
          <div className='title'>Violin Activity</div>
          <div className='amount'>$10</div>
        </div>
        <div>Invited</div>
      </div>
      <div className='requestDeatailCardBody'>
        <div className='section'>
          <div className='userImg'>
            <img src='/imgs/user.png' alt='user' />
          </div>
          <div className='cardUserData'>
            <div className='userName'>
              <div>Natasha Samson</div>
              <GuruzSvg />
            </div>
            <div className='ratings'>
              <StarSvg filled />
              <StarSvg filled />
              <StarSvg filled />
              <StarSvg />
              <StarSvg />
            </div>
          </div>
        </div>
        <div className='timings'>
          <div className='days'>
            <span>SUN</span>
          </div>
          <div>2PM-3PM</div>
        </div>
      </div>
    </div>
  );
};
