import React from 'react';
import { FilterSvg, SearchSvg, StarSvg } from '../../../assets/svg';
import './index.css';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';

export default function FeedbacksPosted({ reviews, state, setState }) {
  return (
    <div className='selectedTab transactions feedbacks'>
      <div className='tabHeader'>
        <div className='tabTitle'>
          Posted <span>{reviews?.length} Feedback</span>
        </div>
        {/* <div className='search'>
          <div className='searchInput'>
            <SearchSvg />
            <input type='text' name='Search' placeholder='Search' />
          </div>
          <div className='filter'>
            <FilterSvg />
            <button>Filter</button>
          </div>
        </div> */}
      </div>
      <dib className='tabBody'>
        <div className='requestCards'>
          {reviews?.length === 0
            ? 'No Feedback Posted'
            : reviews?.map((review) => (
                <RequestCard
                  review={review}
                  state={state}
                  setState={setState}
                />
              ))}
        </div>
      </dib>
    </div>
  );
}

const RequestCard = ({ review }) => {
  console.log(review)
  return (
    <div key={review.id} className='requestCard'>
      <div className='requestCardBody'>
        <div className='txnRow'>
          <div className='feedUserData'>
            <div className='userImg'>
              <img src={review?.createdBy?.image??'/imgs/avatar.svg'} alt='user' />
            </div>
            <div>
              {review?.createdBy?.name}
              <div className='ratings'>
              <StarSvg
                    filled={review?.rating >= 1 ? true : false}
                  />
                  <StarSvg
                    filled={review?.rating >= 2 ? true : false}
                  />
                  <StarSvg
                    filled={review?.rating >= 3 ? true : false}
                  />
                  <StarSvg
                    filled={review?.rating >= 4 ? true : false}
                  />
                  <StarSvg
                    filled={review?.rating>= 5 ? true : false}
                  />
              </div>
            </div>
          </div>
          <div>
            {new Date(review?.createdAt).getDate()}/
            {new Date(review?.createdAt).getMonth()}/
            {new Date(review?.createdAt).getFullYear()}
          </div>
          {/* <div>Apr 27, 2022</div> */}
        </div>
        <div style={{ fontSize: '12px' }}>
          <div>{review?.content}</div>
        </div>
      </div>
    </div>
  );
};
