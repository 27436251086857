import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  EyeSvg,
  FilterSvg,
  GreenArrowUp,
  PeopleDashboardSvg,
  RedArrowDown,
  SearchSvg,
  VerifiedSvg,
} from '../../assets/svg';
import './index.css';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import { ThreeDots } from 'react-loader-spinner';
import { block_user, get_guru_analytics_chart, get_user, get_user_analytics } from '../../API';
import moment from 'moment';
import { ClipLoader } from 'react-spinners';
import { format } from 'date-fns';
import { NumberComponent } from '../../utility';
export default function Gurus({ jwtToken }) {
  const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState('');
  const [searchTxt, setSearchTxt] = useState('');
  const [loading, setLoading] = useState(false);
  const [gurus, setGurus] = useState(null);
  const [gurusAnalytics, setGurusAnalytics] = useState(null);
  const [gurusAnalyticsChart, setGurusAnalyticsChart] = useState(
    { newBatches: 0, newJoined: 0, data: [], totalEarned: 0 }
  );
  const [loadingChart, setLoadingChart] = useState(false);
  // console.log({ gurus, gurusAnalytics });
  const [mapBy, setmapBy] = useState("day");
  const config = {
    headers: { Authorization: `Bearer ${jwtToken}` },
  };

  useEffect(() => {
    setLoading(true)
    get_user(config, 'guru')
      .then((res) => {
        // console.log(res);
        if (res?.status === 200 && res?.data?.success) {

          setLoading(false)
          setGurus(res.data.data);
        } else if (res?.status === 401) {
          setLoading(false)
          setErrMsg(res?.data?.message);
        }
      })
      .catch((err) => {
        setLoading(false)

      });

    get_user_analytics(config, 'guru')
      .then((res) => {
        // console.log(res);
        if (res?.status === 200 && res?.data?.success) {

          setGurusAnalytics(res.data.data);
        } else if (res?.status === 401) {
          setErrMsg(res?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      })
  }, []);
  useEffect(() => {
    guruChatData()
  }, [mapBy])


  const guruChatData = async () => {
    try {
      setLoadingChart(true)
      const res = await get_guru_analytics_chart(config, mapBy)

      const data = res.data.data.timewise.series.map((data) => {
        return {
          name: mapBy === "day" ? format(new Date(data._id), "h:mm a") : mapBy === "week" ? format(new Date(data._id), "dd MMM") : mapBy === "month" ? format(new Date(data._id), "dd MMM") : format(new Date(data._id), "MMM"),
          uv: data.value
        }
      })

      setGurusAnalyticsChart({
        totalEarned: res?.data?.data?.totalEarned, newBatches: res?.data?.data?.newBatches, newJoined: res?.data?.data?.newJoined, data: data
      })
      setLoadingChart(false)
    } catch (error) {
      console.log(error)
      setLoadingChart(false)
    }
  }

  const formatDate = (date) => {
    return moment(date).format('DD-MMM-yyyy');
  };

  const filteredGurus = gurus?.filter((e) =>
    e.name?.toLowerCase().includes(searchTxt.toLowerCase())
  );

  const GetUsers = async () => {
    await get_user(config, 'guru')
      .then((res) => {
        // console.log(res);
        if (res?.status === 200 && res?.data?.success) {


          setGurus(res.data.data);
        } else if (res?.status === 401) {

          setErrMsg(res?.data?.message);
        }
      })
      .catch((err) => {


      });

  }
  const data = [
    {
      name: 'S',
      uv: 0,
    },
    {
      name: 'M',
      uv: 0,
    },
    {
      name: 'T',
      uv: 0,
    },
    {
      name: 'W',
      uv: 0,
    },
    {
      name: 'T',
      uv: 0,
    },
    {
      name: 'F',
      uv: 0,
    },
    {
      name: 'S',
      uv: 0,
    },
  ];

  return (
    <div className='page students gurus'>
      <div className='studentSection1'>
        <div className='studentsHeader'>
          <div className='pageNmae'>
            <div>Gurus</div>
            <div>View & Manage Gurus</div>
          </div>
          <div className='search'>
            <div className='searchInput'>
              <SearchSvg />
              <input
                type='text'
                name='Search'
                value={searchTxt}
                onChange={(e) => setSearchTxt(e.target.value)}
                placeholder='Search'
              />
            </div>
          </div>
        </div>
        <div className='studentBody'>
          <div className='studentList'>
            <div className='tableTitle'>Guru List</div>
            <table className='container'>
              <thead>
                <tr>
                  <td>Guru Name</td>
                  <td>Status</td>
                  <td>Location</td>
                  <td>Joined Date</td>
                  <td>Earned</td>
                  <td className='subscription'></td>
                  <td className='actions'></td>
                </tr>
              </thead>
              {loading ?
                <tbody>
                  <tr>
                    <td class="loader-container">
                      <ClipLoader size={25} color="black" />
                    </td>
                  </tr>
                </tbody> : <tbody>
                  {!filteredGurus ? (
                    <div className='loader_wrapper'>
                      <ThreeDots wrapperClass='loader' />
                    </div>
                  ) : filteredGurus?.length === 0 ? (
                    <tr className='text-center text-14 bg-transparent'>
                      No Gurus Available
                    </tr>
                  ) : (filteredGurus.map((e) => (
                    <tr key={e}>
                      <td>{e.name}</td>
                      <td>
                        <div className='verified'>
                          {e.status === "VERIFIED" ? (
                            <>
                              <VerifiedSvg /> Verified
                            </>
                          ) : e.isBlocked ? (
                            <>
                              <VerifiedSvg /> Blocked
                            </>
                          ) : (
                            'Not Verified'
                          )}
                        </div>
                      </td>
                      <td>{e?.location?.fullAddress ? e?.location?.fullAddress : "N/A"}</td>
                      <td>{formatDate(e.createdAt)}</td>
                      <td className='feeSpent'>${e?.user_payment?.amount_incoming}</td>
                      <Suspend isBlocked={e.isBlocked} id={e.id} GetUsers={GetUsers} />
                      <td className='actions'>
                        <div onClick={() => navigate(`/guru-detail/${e.id}`)} className='action'>
                          <EyeSvg />
                        </div>
                      </td>
                    </tr>
                  ))
                  )}
                </tbody>}
            </table>
          </div>
        </div>
      </div>
      <div className='analytics'>
        <div className='analyticsHeader'>
          <div className='title'>Analytics</div>
          <div className='analyticsView'>
            <span onClick={() => {
              setmapBy("day")

            }} className={`${mapBy == "day" ? "selected" : ""}`}>D</span>
            <span onClick={() => {
              setmapBy("week")

            }} className={`${mapBy == "week" ? "selected" : ""}`}>W</span>
            <span onClick={() => {
              setmapBy("month")

            }} className={`${mapBy == "month" ? "selected" : ""}`}>M</span>
            <span onClick={() => {
              setmapBy("year")

            }} className={`${mapBy == "year" ? "selected" : ""}`}>Y</span>
          </div>
        </div>
        <div className='totalStudents'>
          <div className='icon'>
            <PeopleDashboardSvg />
          </div>
          <div>
            {gurusAnalytics?.gurusCount}
            <div className='titie'>Total Gurus</div>
          </div>
        </div>
        <div className='joinedLeft'>
          <div className='section'>
            Joined
            <div className='stats'>
              {gurusAnalyticsChart.newJoined.toLocaleString()}
              {gurusAnalyticsChart.newJoined > 0 && <GreenArrowUp />}
            </div>
          </div>
          <div className='section'>

          </div>
          <div className='section'>
            Batches Created
            <div className='stats'>{gurusAnalytics?.batchesCount}</div>
          </div>
        </div>
        <div className='feeAnalytics'>
          <div className='title'>Earnings Analytics</div>
          <div className='studentSpent'>
            Coaches Spent
            <div className='amount'>${NumberComponent(gurusAnalytics?.totalEarned)}</div>
          </div>
          <div className='feeAnalyticsChart'>
            <ResponsiveContainer width='100%' height='100%'>
              {
                loadingChart ?
                  <div className={"chartCenter"}>
                    <ClipLoader size={25} color="black" />
                  </div>

                  : <BarChart
                    width={500}
                    height={300}
                    data={gurusAnalyticsChart.data.length > 0 ? gurusAnalyticsChart?.data : data}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                    barSize={5}
                    interval={0}
                    barGap={5}
                  >
                    {/* <CartesianGrid strokeDasharray='0' /> */}
                    <XAxis allowDataOverflow dataKey='name' />
                    <YAxis allowDataOverflow />
                    {/* <Tooltip /> */}
                    <Bar dataKey='uv' fill='#82ca9d' />
                  </BarChart>}
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
}
const Suspend = ({ isBlocked, id, GetUsers }) => {
  const jwtToken = localStorage.getItem('jwtToken');
  const config = {
    headers: { Authorization: `Bearer ${jwtToken}` },
  };
  let [loading, setLoading] = useState()

  const doSuspend = async () => {
    setLoading(true)
    try {
      let data = {
        suspend: !isBlocked
      }
      const res = await block_user(config, id, data)
      await GetUsers()
      setLoading(false)
    } catch (error) {
      setLoading(false)

    }
  }
  return (
    <td className='subscription'>
      <button onClick={() => {
        doSuspend()
      }} className={loading ? `loading` : isBlocked ? `revoke` : "suspend"} style={{

      }} ><div>
          {loading ? `Loading` : isBlocked ? `Revoke` : "Suspend"}  </div>
        {loading && <ClipLoader size={10} color={`#e6b800`} />}

      </button>
    </td>
  )
}