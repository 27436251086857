import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  EyeSvg,
  FilterSvg,
  GreenArrowUp,
  PeopleDashboardSvg,
  RedArrowDown,
  SearchSvg,
} from '../../assets/svg';
import './index.css';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import { block_user, get_guru_analytics_chart, get_student_analytics_chart, get_user, get_user_analytics } from '../../API';
import moment from 'moment';
import { ThreeDots } from 'react-loader-spinner';
import { ClipLoader } from 'react-spinners';
import { format } from 'date-fns';
import { NumberComponent } from "../../utility"
export default function Students({ jwtToken }) {
  const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState('');
  const [searchTxt, setSearchTxt] = useState('');
  const [students, setStudents] = useState(null);
  const [studentsAnalytics, setStudentsAnalytics] = useState(null);
  const [loading, setLoading] = useState(false);
  // console.log({ students, studentsAnalytics });
  const [studentAnalyticsChart, setStudentAnalyticsChart] = useState(
    { totalCount: 0, newJoined: 0, totalSpent: 0, data: [] }
  );
  const [loadingChart, setLoadingChart] = useState(false);
  // console.log({ gurus, gurusAnalytics });
  const [mapBy, setmapBy] = useState("day");
  const config = {
    headers: { Authorization: `Bearer ${jwtToken}` },
  };

  useEffect(() => {
    setLoading(true)
    get_user(config, 'student')
      .then((res) => {
        // console.log(res);
        if (res?.status === 200 && res?.data?.success) {
          setStudents(res.data.data);
          setLoading(false)
        } else if (res?.status === 401) {
          setErrMsg(res?.data?.message);
        }
      })
      .catch((err) => {
        setLoading(false)
        console.log(err);
      });
    get_user_analytics(config, 'student')
      .then((res) => {
        // console.log(res);
        if (res?.status === 200 && res?.data?.success) {
          setStudentsAnalytics(res.data.data);
        } else if (res?.status === 401) {
          setErrMsg(res?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    getAllRequirements()
  }, []);


  useEffect(() => {
    studentChatData()
  }, [mapBy])

  const GetUsers = async () => {
    await get_user(config, 'student')
      .then((res) => {
        // console.log(res);
        if (res?.status === 200 && res?.data?.success) {
          setStudents(res.data.data);
          // setLoading(false)
        } else if (res?.status === 401) {
          setErrMsg(res?.data?.message);
        }
      })
      .catch((err) => {
        // setLoading(false)
        console.log(err);
      });

  }
  const studentChatData = async () => {
    try {
      setLoadingChart(true)
      const res = await get_student_analytics_chart(config, mapBy)

      const data = res.data.data.timewise.series.map((data) => {
        return {
          name: mapBy === "day" ? format(new Date(data._id), "h:mm a") : mapBy === "week" ? format(new Date(data._id), "dd MMM") : mapBy === "month" ? format(new Date(data._id), "dd MMM") : format(new Date(data._id), "MMM"),
          uv: data.value
        }
      })

      setStudentAnalyticsChart({
        totalSpent: res?.data?.data?.totalSpent, totalCount: res?.data?.data?.totalCount, newJoined: res?.data?.data?.newJoined, data: data
      })
      setLoadingChart(false)
    } catch (error) {
      console.log(error)
      setLoadingChart(false)
    }
  }








  const getAllRequirements = () => {
    try {

    } catch (error) {

    }
  }

  const formatDate = (date) => {
    return moment(date).format('DD-MMM-yyyy');
  };

  const filteredStudents = students?.filter((e) =>
    e.name?.toLowerCase().includes(searchTxt.toLowerCase())
  );

  const data = [
    {
      name: 'S',
      uv: 0,
    },
    {
      name: 'M',
      uv: 0,
    },
    {
      name: 'T',
      uv: 0,
    },
    {
      name: 'W',
      uv: 0,
    },
    {
      name: 'T',
      uv: 0,
    },
    {
      name: 'F',
      uv: 0,
    },
    {
      name: 'S',
      uv: 0,
    },
  ];

  return (
    <div className='page students'>
      <div className='studentSection1'>
        <div className='studentsHeader'>
          <div className='pageNmae'>
            <div>Students</div>
            <div>View & Manage Students</div>
          </div>
          <div className='search'>
            <div className='searchInput'>
              <SearchSvg />
              <input
                type='text'
                name='Search'
                value={searchTxt}
                onChange={(e) => setSearchTxt(e.target.value)}
                placeholder='Search'
              />
            </div>
            {/* <div className='filter'>
              <FilterSvg />
              <button>Filter</button>
            </div> */}
          </div>
        </div>
        <div className='studentBody'>
          <div className='studentList'>
            <div className='tableTitle'>Student List</div>
            <table className='container'>
              <thead>
                <tr>
                  <td>Student Name</td>
                  <td>Location</td>
                  <td>Joined Date</td>
                  <td>Fees Spent</td>
                  <td className='subscription'></td>
                  <td className='actions'></td>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td class="loader-container">
                      <ClipLoader size={25} color="black" />
                    </td>
                  </tr>
                ) : filteredStudents?.length === 0 ? (
                  <tr className='text-center text-14 bg-transparent'>
                    No Students Available
                  </tr>
                ) : (
                  filteredStudents?.map((e) => (
                    <tr
                      key={e}

                    >
                      <td>{e.name}</td>
                      <td>{e?.location?.fullAddress ?? "N/A"}</td>
                      <td>{formatDate(e.createdAt)}</td>
                      <td className='feeSpent'>
                        ${e?.details?.totalMoneySpent}
                      </td>
                      <Suspend isBlocked={e.isBlocked} id={e.id} GetUsers={GetUsers} />
                      <td className='actions'>
                        <div onClick={() => navigate(`/student-detail/${e.id}`)} className='action'>
                          <EyeSvg />
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className='analytics'>
        <div className='analyticsHeader'>
          <div className='title'>Analytics</div>
          <div className='analyticsView'>
            <span onClick={() => {
              setmapBy("day")

            }} className={`${mapBy == "day" ? "selected" : ""}`}>D</span>
            <span onClick={() => {
              setmapBy("week")

            }} className={`${mapBy == "week" ? "selected" : ""}`}>W</span>
            <span onClick={() => {
              setmapBy("month")

            }} className={`${mapBy == "month" ? "selected" : ""}`}>M</span>
            <span onClick={() => {
              setmapBy("year")

            }} className={`${mapBy == "year" ? "selected" : ""}`}>Y</span>
          </div>
        </div>
        <div className='totalStudents'>
          <div className='icon'>
            <PeopleDashboardSvg />
          </div>
          <div>
            {NumberComponent(studentAnalyticsChart.totalCount)}
            <div className='titie'>Total Students</div>
          </div>
        </div>
        <div className='joinedLeft'>
          <div className='section'>
            Joined
            <div className='stats'>
              {NumberComponent(studentAnalyticsChart.newJoined)}
              {studentAnalyticsChart.newJoined > 0 && <GreenArrowUp />}
            </div>
          </div>
          <div className='section'>
            Left
            <div className='stats'>
              0
              {/* <RedArrowDown /> */}
            </div>
          </div>
        </div>
        <div className='feeAnalytics'>
          <div className='title'>Fees Analytics</div>
          <div className='studentSpent'>
            Students Spent
            <div className='amount'>${NumberComponent(studentAnalyticsChart?.totalSpent)}</div>
          </div>
          <div className='feeAnalyticsChart'>

            <ResponsiveContainer width='100%' height='100%'>
              {
                loadingChart ?
                  <div className={"chartCenter"}>
                    <ClipLoader size={25} color="black" />
                  </div>

                  : <BarChart
                    width={500}
                    height={300}
                    data={studentAnalyticsChart.data.length > 0 ? studentAnalyticsChart.data : data}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                    barSize={5}
                    interval={0}
                    barGap={5}
                  >
                    {/* <CartesianGrid strokeDasharray='0' /> */}
                    <XAxis dataKey='name' />
                    <YAxis />
                    {/* <Tooltip /> */}
                    <Bar dataKey='uv' fill='#82ca9d' />
                  </BarChart>}
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
}


const Suspend = ({ isBlocked, id, GetUsers }) => {
  const jwtToken = localStorage.getItem('jwtToken');
  const config = {
    headers: { Authorization: `Bearer ${jwtToken}` },
  };
  let [loading, setLoading] = useState()

  const doSuspend = async () => {
    setLoading(true)
    try {
      let data = {
        suspend: !isBlocked
      }
      const res = await block_user(config, id, data)
      await GetUsers()
      setLoading(false)
    } catch (error) {
      setLoading(false)

    }
  }
  return (
    <td className='subscription'>
      <button onClick={() => {
        doSuspend()
      }} className={loading ? `loading` : isBlocked ? `revoke` : "suspend"} style={{

      }} ><div>
          {loading ? `Loading` : isBlocked ? `Revoke` : "Suspend"}  </div>
        {loading && <ClipLoader size={10} color={`#e6b800`} />}

      </button>
    </td>
  )
}