import React, { useState } from 'react';
import './index.css';
import ManageCategories from '../../components/Settings/ManageCategories';
import SplashScreenImages from '../../components/Settings/SplashScreenImages';
import TermsAndCond from '../../components/Settings/TermsAndCond';
import RefundPolicy from '../../components/Settings/RefundPolicy';

export default function Settings({ jwtToken }) {
  const [tab, setTab] = useState(1);

  return (
    <div className='page settings students studentDetails guruDetails'>
      <div className='studentsHeader'>
        <div className='pageNmae'>
          <div>Settings</div>
          <div>Manage App Settings</div>
        </div>
      </div>
      <div className='studentDetail'>
        <div className='studentDetailCard'>
          <div className='studentDetailCardTabs'>
            <div
              onClick={() => setTab(1)}
              className={`studentDetailCardTab ${tab === 1 && 'selected'}`}
            >
              Manage Categories
            </div>
            <div
              onClick={() => setTab(2)}
              className={`studentDetailCardTab ${tab === 2 && 'selected'}`}
            >
             Payment & Platform Margin 
            </div>
            <div
              onClick={() => setTab(3)}
              className={`studentDetailCardTab ${tab === 3 && 'selected'}`}
            >
              Terms & Conditions
            </div>
            <div
              onClick={() => setTab(4)}
              className={`studentDetailCardTab ${tab === 4 && 'selected'}`}
            >
              Refund Policy
            </div>
          </div>
          {tab === 1 ? (
            <ManageCategories jwtToken={jwtToken} />
          ) : tab === 2 ? (
            
            <SplashScreenImages jwtToken={jwtToken} />
          ) : tab === 3 ? (
            <TermsAndCond  jwtToken={jwtToken} />
          ) : tab === 4 ? (
            <RefundPolicy jwtToken={jwtToken} />
          ) : null}
        </div>
      </div>
    </div>
  );
}
