import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowDown, SearchSvg } from '../../assets/svg';
import './index.css';

export default function Header({ userData, ...props }) {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem('jwtToken');
    props.setUserLoggedIn(false);
    navigate('/login');
  };
  console.log(userData)
  return (
    <div className='header'>
      <div className='search'>
        {/* <SearchSvg />
        <input type='text' name='search' placeholder='Search..' /> */}
      </div>
      <div className='userDetail' onClick={handleLogout}>
        <div>
          <div className='userName'>{userData?.data?.account?.name??"Guruz Admin"}</div>
          <div className='role'>{userData?.data?.account?.role??"Admin"}</div>
        </div>
        <div className='userImg'>
          <img src={userData?.data?.account?.image??'/imgs/avatar.svg' }alt='profile' />
        </div>
        <div className='arrowDown'>
          <ArrowDown />
        </div>
      </div>
    </div>
  );
}
