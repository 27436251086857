import React, { useEffect, useState } from 'react';
import { ArrowBackBg, LocationSvg, MenuBGSvg } from '../../assets/svg';
import './index.css';
import ExploreRequestsPosted from '../../components/Student/ExploreRequestsPosted';
import BatchesJoined from '../../components/Student/BatchesJoined';
import Transactions from '../../components/Student/Transactions';
import FeedbacksPosted from '../../components/Student/FeedbacksPosted';
import { useNavigate, useParams } from 'react-router-dom';
import {
  get_batches_enrolled_by_student,
  get_batches_reviews_created_by_student,
  get_requirements_created_by_student,
  get_transactions,
  get_user_by_id,
} from '../../API';
import moment from 'moment';

export default function StudentDetail({ jwtToken }) {
  const [tab, setTab] = useState(1);
  const { id } = useParams();
  const navigate = useNavigate();
  const [state, setState] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false,
  });

  const [errMsg, setErrMsg] = useState('');
  const [requirements, setRequirements] = useState(null);
  const [batchesEnrolled, setBatchesEnrolled] = useState(null);
  const [reviews, setReviews] = useState(null);
  const [user, setUser] = useState(null);
  const [transactions, setTransactions] = useState([]);

  const config = {
    headers: { Authorization: `Bearer ${jwtToken}` },
  };

  useEffect(() => {
    get_requirements_created_by_student(config, id)
      .then((res) => {
        if (res?.status === 200 && res?.data?.success) {
          setRequirements(res.data.data);
        } else if (res?.status === 401) {
          setErrMsg(res?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });

      get_user_by_id(config, id)
      .then((res) => {
        if (res?.status === 200 && res?.data?.success) {
          setUser(res.data.data);
        } else if (res?.status === 401) {
          setErrMsg(res?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    get_batches_enrolled_by_student(config, id)
      .then((res) => {
        // console.log(res);
        if (res?.status === 200 && res?.data?.success) {
          setBatchesEnrolled(res.data.data);
        } else if (res?.status === 401) {
          setErrMsg(res?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    get_batches_reviews_created_by_student(config, id)
      .then((res) => {
        // console.log(res);
        if (res?.status === 200 && res?.data?.success) {
          setReviews(res.data.data);
        } else if (res?.status === 401) {
          setErrMsg(res?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
      get_transactions(config, id)
      .then((res) => {
        // console.log(res);
        if (res?.status === 200 && res?.data?.success) {
          setTransactions(res.data.data.data);
        } else if (res?.status === 401) {
          setErrMsg(res?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

 

  const formatDate = (date) => {
    return moment(date).format('DD-MMM-yyyy');
  };
  return (
    <div className='page students studentDetails'>
      <div className='studentsHeader'>
        <div className='pageNmae'>
          <div>Student Detail</div>
          <div>View Student Details</div>
        </div>
      </div>
      <div className='studentDetail'>
        <div className='studentProfileCard'>
          <div className='profileCardHeader'>
            <div className='back' onClick={() => navigate(-1)}>
              <ArrowBackBg />
            </div>
            <div className='menu'>
              <MenuBGSvg />
            </div>
          </div>
          <div className='profileUserDetail'>
            <div className='img'>
              <img src={user?.user?.image??'/imgs/avatar.svg'} alt='user' />
            </div>
            <div className='userName'>{user?.user?.name ?? '-'}</div>
            <div className='location'>
              {/* <LocationSvg />
              <span>{user?.user?.location ?? '-'}</span> */}
            </div>
          </div>
          <div className='userAppData'>
            <div className='row'>
              <div className='rowTitle'>Joined Date</div>
              <div>{formatDate(user?.user?.createdAt)}</div>
            </div>
            <div className='row'>
              <div className='rowTitle'>Batches Joined</div>
              <div>{user?.batchesCount}</div>
            </div>
            <div className='row'>
              <div className='rowTitle'>Fees Spent</div>
              <div>${user?.fees}</div>
            </div>
            {/* <div className='row'>
              <div className='rowTitle'>Hours Spent on app</div>
              <div>{user?.timeSpent}mins</div>
            </div> */}
          </div>
        </div>
        <div className='studentDetailCard'>
          <div className='studentDetailCardTabs'>
            <div
              onClick={() => setTab(1)}
              className={`studentDetailCardTab ${tab === 1 && 'selected'}`}
            >
              Explore Requests Posted
            </div>
            <div
              onClick={() => setTab(2)}
              className={`studentDetailCardTab ${tab === 2 && 'selected'}`}
            >
              Batches Joined
            </div>
            <div
              onClick={() => setTab(3)}
              className={`studentDetailCardTab ${tab === 3 && 'selected'}`}
            >
              Transactions
            </div>
            <div
              onClick={() => setTab(4)}
              className={`studentDetailCardTab ${tab === 4 && 'selected'}`}
            >
              Feedbacks Posted
            </div>
          </div>
          {tab === 1 ? (
            <ExploreRequestsPosted
              requirements={requirements}
              state={state}
              setState={setState}
            />
          ) : tab === 2 ? (
            <BatchesJoined
              batchesEnrolled={batchesEnrolled}
              state={state}
              setState={setState}
            />
          ) : tab === 3 ? (
            <Transactions
            transactions={transactions} state={state} setState={setState} />
          ) : tab === 4 ? (
            <FeedbacksPosted
              reviews={reviews}
              state={state}
              setState={setState}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}
