import React, { useState } from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Students from './pages/Students';
import './App.css';
import StudentDetail from './pages/StudentDetail';
import Gurus from './pages/Gurus';
import GuruDetail from './pages/GuruDetail';
import Settings from './pages/Settings';
import axios from 'axios';
import Login from './pages/Login';
import Layout from './layout';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
// axios.defaults.baseURL = 'https://ds21yp9cflaig.cloudfront.net/api/v1';
axios.defaults.baseURL = 'https://d3acmedh3kvwe0.cloudfront.net/v1';

export default function App() {
  const [userData, setUserData] = useState(false);
  const jwtToken = localStorage.getItem('jwtToken');
  const [userLoggedIn, setUserLoggedIn] = useState(jwtToken ? true : false);
 

  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
          <Route
            element={
              <Layout
                {...{ setUserLoggedIn, userLoggedIn }}
                userData={userData}
              />
            }
          >
            <Route path='/' element={<Dashboard jwtToken={jwtToken} />} />
            <Route
              path='/students'
              element={<Students jwtToken={jwtToken} />}
            />
            <Route
              path='/student-detail/:id'
              element={<StudentDetail jwtToken={jwtToken} />}
            />
            <Route path='/gurus' element={<Gurus jwtToken={jwtToken} />} />
            <Route
              path='/guru-detail/:id'
              element={<GuruDetail jwtToken={jwtToken} />}
            />
            <Route
              path='/settings'
              element={<Settings jwtToken={jwtToken} />}
            />
          </Route>
          <Route
            path='/login'
            element={
              <Login
                userData={userData}
                setUserData={setUserData}
                userLoggedIn={userLoggedIn}
                setUserLoggedIn={setUserLoggedIn}
              />
            }
          />
        </Routes>
      </BrowserRouter>
      <ToastContainer
      
      autoClose={5000}
      />

    </div>
  );
}
