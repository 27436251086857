import React, { useEffect, useState } from 'react';
import { get_policy, update_policy } from '../../../API';
import { SearchSvg } from '../../../assets/svg';
import './index.css';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';

export default function RefundPolicy() {
  const jwtToken = localStorage.getItem('jwtToken');
  const [loading, setLoading] = useState(false)
  const [policy, setPolicy] = useState(null);
  const config = {
    headers: { Authorization: `Bearer ${jwtToken}` },
  };
  function getPolicy() {
    setLoading(true)
    get_policy(config)
      .then((res) => {
        if (res?.status === 200 && res?.data?.success) {
          setPolicy(res.data.data);
          setLoading(false)
        } else if (res?.status === 401) {
          setLoading(false)
         
        }
      })
      .catch((err) => {
        setLoading(false)
        console.log(err);
      });
  }

  useEffect(() => {
    getPolicy();
  }, []);

 

const savePolicy=async()=>{
  try {
    
   delete policy._id
      setLoading(true)
   await   update_policy(config,policy)
       
      setLoading(false)
      getPolicy();
      toast.success(  'Terms Updated successFully', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

     
  } catch (error) {
    setLoading(false)
    
    toast.error(error.response?.data?.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    
  }
}

  const terms = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
  Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. `;
  return (
    <div className='selectedTab manageCategories transactions termsAndCond'>
      <div className='tabHeader'>
        <div className='tabTitle'>Manage Refund Policy</div>
        <div className='search'>
          <div className='searchInput'>
            <SearchSvg />
            <input type='text' name='Search' placeholder='Search' />
          </div>
        </div>
      </div>
      <dib className='tabBody'>
        <div className='termsInput'>
          <textarea
            type='text'
            name='termAndCond'
            placeholder='Refund Policy'
            value={policy?.refund}
            onChange={(e)=>{
           
              setPolicy({
                ...policy,
                refund:e.target.value,
                
              })
            }}
          />
        </div>
        <div className='saveBtn'>
          <button disabled={loading} onClick={()=>{
            savePolicy()
          }} style={{position: 'relative'}} >Save Changes
          <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '90%',
            transform: 'translate(-50%, -50%)',
          }}
        >

           {loading&& <ClipLoader size={12} color="white" />}



        </div>
         
          </button>
   
        </div>
      </dib>
    </div>
  );
}
