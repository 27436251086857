import { Outlet, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';

const Layout = ({ userData, ...props }) => {
  const navigate = useNavigate();

  // if (props.userLoggedIn) {
  //   navigate('/login');
  // }
  return (
    <div className='guruzAdmin'>
      <div className='sidebarWrapper'>
        <Sidebar />
      </div>
      <div className='guruzAdminMain'>
        <div className='headerWrapper'>
          <Header userData={userData} {...props} />
        </div>
        <div className='guruzAdminBody'>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
