import axios from 'axios';

const get_user_by_id = async (config, id) => {
  try {
    const res = await axios.get(`/users/${id}/stats`, config);
    return res;
  } catch ({ response }) {
    return response;
  }
};

const get_guru_ratings = async (config, id) => {
  try {
    const res = await axios.get(`/guru/${id}/rating`, config);
    return res;
  } catch ({ response }) {
    return response;
  }
};

const get_user = async (config, role) => {
  try {
    const res = await axios.get(
      `/users?role=${role}&sortBy=createdAt:desc`,
      config
    );
    return res;
  } catch ({ response }) {
    return response;
  }
};
const get_user_top_guruz = async (config,sortBY) => {
  try {
    const res = await axios.get(
      `users?role=guru&page=1&limit=3&sortBy=${sortBY}`,
      config
    );
    return res;
  } catch ({ response }) {
    return response;
  }
};

const get_user_analytics = async (config, role) => {
  try {
    const res = await axios.get(`/users/analytics?role=${role}`, config);
    return res;
  } catch ({ response }) {
    return response;
  }
};

const  block_user = async (config, userId, data) => {
  try {
    const res = await axios.put(`/users/${userId}/suspend`, data,config);
    return res;
  } catch ({ response }) {
    return response;
  }
};

const get_user_analytics_chart = async (config, role) => {
  try {
    const res = await axios.get(`/users/analytics?timeframe=year`, config);
    return res;
  } catch ({ response }) {
    return response;
  }
};
const get_guru_analytics_chart = async (config, by) => {
  try {
    const res = await axios.get(`/users/guru/analytics?timeframe=${by}`, config);
    return res;
  } catch ({ response }) {
    return response;
  }
}
const get_student_analytics_chart = async (config, by) => {
  try {
    const res = await axios.get(`/users/student/analytics?timeframe=${by}`, config);
    return res;
  } catch ({ response }) {
    return response;
  }
}
const get_batches_created_by_guru = async (config, id) => {
  try {
    const res = await axios.get(`/batch?createdBy=${id}`, config);
    return res;
  } catch ({ response }) {
    return response;
  }
};
const get_batches_created_by_guru_by_id = async (config, id) => {
  try {
    const res = await axios.get(`/batch/${id}`, config);
    return res;
  } catch ({ response }) {
    return response;
  }
};
const get_reviews_batch_created_by_guru_by_id = async (config, id) => {
  try {
    const res = await axios.get(`/batch/${id}/review`, config);
    return res;
  } catch ({ response }) {
    return response;
  }
};

const get_rating_batch_created_by_guru_by_id = async (config, id) => {
  try {
    const res = await axios.get(`/batch/${id}/rating`, config);
    return res;
  } catch ({ response }) {
    return response;
  }
}
const get_requirements_created_by_student = async (config, id) => {
  try {
    const res = await axios.get(`/requirement?createdBy=${id}`, config);
    return res;
  } catch ({ response }) {
    return response;
  }
};

const get_batches_enrolled_by_student = async (config, id) => {
  try {
    const res = await axios.get(`/batch?enrollments=${id}`, config);
    return res;
  } catch ({ response }) {
    return response;
  }
};

const get_batches_reviews_created_by_student = async (config, id) => {
  try {
    const res = await axios.get(`/batch-reviews?createdBy=${id}`, config);
    return res;
  } catch ({ response }) {
    return response;
  }
};

const get_batches_reviews_for_guru = async (config, id) => {
  try {
    const res = await axios.get(`/batch-reviews?guru=${id}`, config);
    return res;
  } catch ({ response }) {
    return response;
  }
};
const get_transactions = async (config, id) => {
  try {
    const res = await axios.get(`/users/${id}/transactions`, config);
    return res;
  } catch ({ response }) {
    return response;
  }
};
const get_policy = async (config) => {
  try {
    const res = await axios.get(`/cms/policy`, config);
    return res;
  } catch ({ response }) {
    return response;
  }
};

const update_policy = async (config, data) => {
  try {
    const res = await axios.put(`/cms/policy`, data, config);
    return res;
  } catch ({ response }) {
    return response;
  }
};
const get_payment_percentage = async (config, data) => {
  try {
    const res = await axios.get(`/config/platform-fees`, data, config);
    return res;
  } catch ({ response }) {
    return response;
  }
};

const update_payment_percentage = async (config, data) => {
  try {
    const res = await axios.put(`/config/platform-fees`, data, config);
    return res;
  } catch ({ response }) {
    return response;
  }
};

const get_top_category = async (config) => {
  try {
    const res = await axios.get(`/batch/top-categories`, config);
    return res;
  } catch ({ response }) {
    return response;
  }
};

const get_category = async (config) => {
  try {
    const res = await axios.get(`/cms/category`, config);
    return res;
  } catch ({ response }) {
    return response;
  }
};

const create_category = async (config, data) => {
  try {
    const res = await axios.post(`/cms/category`, data, config);
    return res;
  } catch ({ response }) {
    return response;
  }
};

const update_category = async (config, data) => {
  try {
    const res = await axios.put(`/cms/category`, data, config);
    return res;
  } catch ({ response }) {
    return response;
  }
};

const delete_category = async (config, name) => {
  try {
    const res = await axios.delete(`/cms/category/${name}`, config);
    return res;
  } catch ({ response }) {
    return response;
  }
};

const authenticate = async (data) => {
  try {
    const res = await axios.post(`/auth/login`, data);
    return res;
  } catch ({ response }) {
    return response;
  }
};

export {
  get_guru_ratings,
  get_user,
  get_user_by_id,
  get_user_analytics,
  get_batches_created_by_guru,
  get_requirements_created_by_student,
  get_batches_enrolled_by_student,
  get_batches_reviews_created_by_student,
  get_batches_reviews_for_guru,
  get_policy,
  update_policy,
  get_top_category,
  get_category,
  create_category,
  get_transactions,
  update_category,
  delete_category,
  authenticate,
  get_batches_created_by_guru_by_id,
  get_user_analytics_chart,
  get_guru_analytics_chart,
  get_student_analytics_chart,
  update_payment_percentage,
  get_payment_percentage,
  get_reviews_batch_created_by_guru_by_id,
  get_user_top_guruz,
  block_user,
  get_rating_batch_created_by_guru_by_id
};
