import React, { useEffect, useState } from 'react';
import {
  MoneyDashboardSvg,
  PeopleDashboardSvg,
  WistleDashboardSvg,
  StarSvg,
} from '../../assets/svg';
import './index.css';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from 'recharts';
import { get_top_category, get_user, get_user_analytics_chart, get_user_top_guruz } from '../../API';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { ClipLoader } from 'react-spinners';
export default function Dashboard({ jwtToken }) {
  const navigate = useNavigate();
  const [students, setStudents] = useState(null);
  const [gurus, setGurus] = useState(null);
  const [topGurus, setTopGurus] = useState(null);
  const [topGurusLoading, setTopGurusLoading] = useState(false);

  const [topCategories, setTopCategories] = useState(null);
  const [studentsAnalatics, setStudentsAnalatics] = useState([]);

  const [errMsg, setErrMsg] = useState('');
  const data = [
    {
      name: 'Jan',
      uv: 0,
      pv: 0,
      // amt: 24,
    },
    {
      name: 'Feb',
      uv: 0,
      pv: 0,
      // amt: 22,
    },
    {
      name: 'Mar',
      uv: 0,
      pv: 0,
      // amt: 50,
    },
    {
      name: 'Apr',
      uv: 0,
      pv: 0,
      // amt: 20,
    },
    {
      name: 'May',
      uv: 0,
      pv: 0,
      // amt: 25,
    },
    {
      name: 'Jun',
      uv: 0,
      pv: 0,
      // amt: 21,
    },
    {
      name: 'Jul',
      uv: 0,
      pv: 0,
      // amt: 24,
    },
    {
      name: 'Aug',
      uv: 0,
      pv: 0,
      // amt: 22,
    },
    {
      name: 'Sep',
      uv: 0,
      pv: 0,
      // amt: 50,
    },
    {
      name: 'Oct',
      uv: 0,
      pv: 0,
      // amt: 20,
    },
    {
      name: 'Nov',
      uv: 0,
      pv: 0,
      // amt: 21,
    },
    {
      name: 'Dec',
      uv: 0,
      pv: 0,

    },
  ];
  // const categoriesData = [
  //   { name: 'Guitar', value: 20, color: '#F5A041' },
  //   { name: 'Tennis', value: 30, color: '#6F51EC' },
  //   { name: 'Yoga', value: 20, color: '#33D59E' },
  //   { name: 'Karate', value: 20, color: '#4CABFD' },
  //   { name: 'Piano', value: 10, color: '#FE4C61' },
  // ];
  const categoriesData = topCategories?.map((category) => ({
    name: category?.category,
    value: category?.count,
    color: getColor(),
  }));
  function getColor() {
    return (
      'hsl(' +
      360 * Math.random() +
      ',' +
      (25 + 70 * Math.random()) +
      '%,' +
      (40 + 10 * Math.random()) +
      '%)'
    );
  }

  const [analyticsTab, setAnalyticsTab] = useState('popular');
  // console.log(
  //   'getColor',
  //   topCategories.map((category) => ({
  //     name: category?.category,
  //     value: category?.count,
  //     color: getColor(),
  //   }))
  // );

  useEffect(() => {
    if (!jwtToken) {
      navigate('/login');
    }
  }, []);

  const config = {
    headers: { Authorization: `Bearer ${jwtToken}` },
  };
  useEffect(() => {
    get_user(config, 'student')
      .then((res) => {
        // console.log(res);
        if (res?.status === 200 && res?.data?.success) {
          setStudents(res.data.data);
        } else if (res?.status === 401) {
          setErrMsg(res?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    get_user(config, 'guru')
      .then((res) => {
        // console.log(res);
        if (res?.status === 200 && res?.data?.success) {
          setGurus(res.data.data);
        } else if (res?.status === 401) {
          setErrMsg(res?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    get_top_category(config)
      .then((res) => {
        // console.log(res);
        if (res?.status === 200 && res?.data?.success) {
          setTopCategories(res.data.data);
        } else if (res?.status === 401) {
          setErrMsg(res?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    get_user_top_guruz(config, "amount_incoming:desc")
      .then((res) => {
        // console.log(res);
        if (res?.status === 200 && res?.data?.success) {
          // topGurus, 
          setTopGurus(res.data.data);
        } else if (res?.status === 401) {
          setErrMsg(res?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });


    StudentStats()
  }, []);
  const StudentStats = async () => {


    try {
      const res = await get_user_analytics_chart(config)
      const data = res.data?.data?.timewise?.series.map((data) => {
        return ({
          name: format(new Date(data.id), "MMM"),
          uv: data.value,
          pv: 39,
          // amt: 20,
        })
      })
      setStudentsAnalatics(data)

    } catch (error) {
      console.log(error)
    }

  }
  useEffect(() => {
    setTopGurusLoading(true)
    get_user_top_guruz(config, "amount_incoming:desc")
      .then((res) => {
        // console.log(res);
        if (res?.status === 200 && res?.data?.success) {
          // topGurus, 
          setTopGurus(res.data.data);
          setTopGurusLoading(false)
        } else if (res?.status === 401) {
          setErrMsg(res?.data?.message);
          setTopGurusLoading(false)
        }
      })
      .catch((err) => {
        console.log(err);
        setTopGurusLoading(false)
      });



  }, [analyticsTab])
  console.log(topGurus)
  return (
    <div className='page'>
      <div className='pageNmae'>
        <div>Dashboard</div>
        <div>Guruz app analytics</div>
      </div>
      <div className='dashboardWidgets'>
        <div className='dashboardWidget totalStudent'>
          <div className='widgetIcon'>
            <WistleDashboardSvg />
          </div>
          <div className='widgetStats'>
            <div>{students?.length}</div>
            <div className='widgetName'>Total Students</div>
          </div>
        </div>
        <div className='dashboardWidget totalGuruz'>
          <div className='widgetIcon'>
            <PeopleDashboardSvg />
          </div>
          <div className='widgetStats'>
            <div>{gurus?.length}</div>
            <div className='widgetName'>Total Guruz</div>
          </div>
        </div>
        <div className='dashboardWidget totalRevenue'>
          <div className='widgetIcon'>
            <MoneyDashboardSvg />
          </div>
          <div className='widgetStats'>
            <div>$0</div>
            <div className='widgetName'>Total Revenue</div>
          </div>
        </div>
      </div>
      <div className='dashboardAnalytics'>
        <div className='dashboardAnalytic'>
          <div className='analyticHeader'>
            <div className='analyticName'>Students Number Change Per Month</div>
            <div>
              <div className='joined'>
                <div className='dot'></div>
                <div>Joined</div>
              </div>
              <div className='left'>
                <div className='dot'></div>
                <div>Left</div>
              </div>
            </div>
          </div>
          <div className='analiticChart'>
            <ResponsiveContainer width='100%' height='100%'>
              <BarChart
                width={500}
                height={300}
                data={studentsAnalatics?.length > 0 ? studentsAnalatics : data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
                barSize={5}
                interval={0}
                barGap={5}
              >
                {/* <CartesianGrid strokeDasharray='10 3' /> */}
                <XAxis dataKey='name' />
                <YAxis />
                {/* <Tooltip /> */}
                <Bar
                  dataKey='pv'
                  fill='#f5a041'
                // style={{ marginRight: '10px' }}
                />
                <Bar dataKey='uv' fill='#4cabfd' />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className='dashboardAnalytic guruzAnalytics'>
          <div className='analyticHeader'>
            <div className='analyticName'>Guruz Analytics</div>
            <div className='analyticTabs'>
              <div
                onClick={() => setAnalyticsTab('popular')}
                className={`analyticTab ${analyticsTab === 'popular' && 'selected'
                  }`}
              >
                Most Popular
              </div>
              <div
                onClick={() => setAnalyticsTab('performing')}
                className={`analyticTab ${analyticsTab === 'performing' && 'selected'
                  } `}
              >
                Most Performing
              </div>
            </div>
          </div>
          <div className='analyticTiles'>
            {!topGurusLoading ? topGurus?.map((item, index) => (
              <div className='analyticTile'>
                <div className='analyticTileDetail'>
                  <div className='img'>
                    <img src={item.image ?? '/imgs/avatar.svg'} alt='profile' />
                  </div>
                  <div className=''>
                    <div className='name'>{item.name}</div>
                    <div className='ratings'>
                      <StarSvg filled={item.details.avgRating.rating   >= 1 ? true : false} />
                      <StarSvg filled={item.details.avgRating.rating >= 2 ? true : false} />
                      <StarSvg filled={item.details.avgRating.rating >= 3 ? true : false} />
                      <StarSvg filled={item.details.avgRating.rating >= 4 ? true : false} />
                      <StarSvg filled={item.details.avgRating.rating >= 5 ? true : false} />
                    </div>
                  </div>
                </div>
                <div className='earning'>
                  <div className='earned'>$ {item.user_payment.amount_incoming}</div>
                  Earned
                </div>
              </div>
            )) :<ClipLoader size={15} color="white" />}
          </div>
        </div>
        {categoriesData && (
          <div className='dashboardAnalytic topCategories'>
            <div className=''>
              <div className='analyticName'>Top 5 Categories</div>
            </div>
            <div className='categoryChart'>
              <PieChart width={160} height={160}>
                <Pie
                  data={categoriesData}
                  cx={80}
                  cy={80}
                  innerRadius={65}
                  outerRadius={75}
                  fill='#8884d8'
                  paddingAngle={0}
                  dataKey='value'
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {categoriesData?.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
              </PieChart>
            </div>
            <div className='categoryChartLabels'>
              {categoriesData?.map((categorie) => (
                <div className='categoryChartLabel'>
                  <div
                    className='dot'
                    style={{ background: categorie.color }}
                  ></div>
                  <div>
                    {categorie.name}
                    <span>{categorie.value}%</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
