import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  WistleSvg,
  DashboardSvg,
  GuruzLogo,
  MoneySvg,
  SettingsSvg,
  PeopleSvg,
} from '../../assets/svg';
import './index.css';

export default function Sidebar() {
  const location = useLocation();
  // console.log(location.pathname.slice(0, 15));
  const stdntDtlLocation = location.pathname.slice(0, 15);
  const guruzDtlLocation = location.pathname.slice(0, 12);
  // console.log(guruzDtlLocation);
  return (
    <div className='sidebar'>
      <div>
        <div className='logo'>
          <GuruzLogo />
        </div>
        <div className='pageLinks'>
          <Link
            to={'/'}
            className={`pageLink ${location.pathname === '/' && 'active'}`}
          >
            <DashboardSvg />
          </Link>
          <Link
            to={'/gurus'}
            className={`pageLink ${
              (location.pathname === '/gurus' ||
                guruzDtlLocation === '/guru-detail') &&
              'active'
            }`}
          >
            <WistleSvg />
          </Link>
          <Link
            to={'/students'}
            className={`pageLink ${
              (location.pathname === '/students' ||
                stdntDtlLocation === '/student-detail') &&
              'active'
            }`}
          >
            <PeopleSvg />
          </Link>
          {/* <Link to={'/'} className='pageLink'>
            <MoneySvg />
          </Link> */}
        </div>
      </div>
      <div>
        <Link to={'/settings'} className={`settings`}>
          <div
            className={`pageLink  ${
              location.pathname === '/settings' && 'active'
            }`}
          >
            <SettingsSvg />
          </div>
        </Link>
      </div>
    </div>
  );
}
