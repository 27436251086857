import React, { useEffect, useState } from 'react';
import { CamersSvg, CrossBGWhieSvg, SearchSvg } from '../../../assets/svg';
import './index.css';
import { get_payment_percentage, get_policy, update_payment_percentage, update_policy } from '../../../API';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';

export default function SplashScreenImages() {

  const jwtToken = localStorage.getItem('jwtToken');
  const [loading, setLoading] = useState(false)
  const [policy, setPolicy] = useState(null);
  const config = {
    headers: { Authorization: `Bearer ${jwtToken}` },
  };
  function getPolicy() {
    setLoading(true)
    get_payment_percentage(config)
      .then((res) => {
        if (res?.status === 200 && res?.data?.success) {
          setPolicy(res.data.data.data.platform_fees);
          setLoading(false)
        } else if (res?.status === 401) {
          setLoading(false)

        }
      })
      .catch((err) => {
        setLoading(false)
        console.log(err);
      });
  }

  useEffect(() => {
    getPolicy();
  }, []);

 

  const savePolicy = async () => {
    try {
      // update_payment_percentage,
      // get_payment_percentage

      if (/^\d+(\.\d+)?$/.test(policy?.amount)&&parseFloat(policy?.amount)<=100) {
        delete policy._id
        setLoading(true)
        await update_payment_percentage(config, policy)

        setLoading(false)
        getPolicy();
        toast.success('Guruz Platform Fees percentage Updated successFully', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

      }else {
        setLoading(false)

        toast.error("Amount Must be a Valid Number ", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }


    } catch (error) {
      setLoading(false)

      toast.error(error.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

    }
  }

  const terms = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
  Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. `;
  return (
    <div className='selectedTab manageCategories transactions termsAndCond'>
      <div className='tabHeader'>
        <div className='tabTitle'>Manage Guruz Platform Fees (percentage)</div>
        <div className='search'>
          <div className='searchInput'>
            <SearchSvg />
            <input type='text' name='Search' placeholder='Search' />
          </div>
        </div>
      </div>
      <dib className='tabBody'>
        <div className='termsInput'>
          <textarea
            type='text'
            name='termAndCond'
            placeholder='Guruz Platform Fees percentage...'
            value={policy?.amount}
            onChange={(e) => {
           
              setPolicy({
                ...policy,
                amount: e.target.value,

              })
            }}
          />
        </div>
        <div className='saveBtn'>
          <button disabled={loading} onClick={() => {
            savePolicy()
          }} style={{ position: 'relative' }} >Save Changes
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '90%',
                transform: 'translate(-50%, -50%)',
              }}
            >

              {loading && <ClipLoader size={12} color="white" />}



            </div>

          </button>

        </div>
      </dib>
    </div>)

  // return (
  // <div className='selectedTab manageCategories transactions splashScreenImages'>
  //   <div className='tabHeader'>
  //     <div className='tabTitle'>
  //       Uploaded <span>02 Images</span>
  //     </div>
  //     <div className='search'>
  //       <div className='searchInput'>
  //         <SearchSvg />
  //         <input type='text' name='Search' placeholder='Search' />
  //       </div>
  //     </div>
  //   </div>
  //   <dib className='tabBody'>
  //     <div className='uploadImg'>
  //       <CamersSvg />
  //       <div>Upload Image</div>
  //     </div>
  //     <div className='uploadedImages'>
  //       <div className='Image'>
  //         <div className='remove'>
  //           <CrossBGWhieSvg />
  //         </div>
  //         <img src='/imgs/splash1.png' alt='splash' />
  //         <dic className='imgTagline'>
  //           <input type='text' name='imgTagline' placeholder='Tagline' />
  //         </dic>
  //       </div>
  //       <div className='Image'>
  //         <div className='remove'>
  //           <CrossBGWhieSvg />
  //         </div>
  //         <img src='/imgs/splash2.png' alt='splash' />
  //         <dic className='imgTagline'>
  //           <input type='text' name='imgTagline' placeholder='Tagline' />
  //         </dic>
  //       </div>
  //       <div className='Image'>
  //         <div className='remove'>
  //           <CrossBGWhieSvg />
  //         </div>
  //         <img src='/imgs/splash3.png' alt='splash' />
  //         <dic className='imgTagline'>
  //           <input type='text' name='imgTagline' placeholder='Tagline' />
  //         </dic>
  //       </div>
  //     </div>
  //   </dib>
  // </div>
  // );
}
