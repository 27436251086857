import React, { useEffect, useState } from 'react';
import {
  FilterSvg,
  LocationSvg,
  SearchSvg,
  StarSvg,
  ViolenSvg,
} from '../../../assets/svg';
import './index.css';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { convertedNumTOTime } from '../../../utility';
import { get_batches_created_by_guru_by_id, get_rating_batch_created_by_guru_by_id, get_reviews_batch_created_by_guru_by_id } from '../../../API';
import { format } from 'date-fns';

export default function BatchesCreated({ batchesCreated, state, setState }) {
  return (
    <div className='selectedTab btchesJoined batchesCreated'>
      <div className='tabHeader'>
        <div className='tabTitle'>
          Created <span>{batchesCreated?.length} Batches </span>
        </div>
        {/* <div className='search'>
          <div className='searchInput'>
            <SearchSvg />
            <input type='text' name='Search' placeholder='Search' />
          </div>
          <div className='filter'>
            <FilterSvg />
            <button>Filter</button>
          </div>
        </div> */}
      </div>
      <dib className='tabBody'>
        <div className='requestCards'>
          {batchesCreated?.length === 0
            ? 'No Batches created'
            : batchesCreated?.map((batch) => (
              <BatchesJoinedCard
                batch={batch}
                state={state}
                setState={setState}
              />
            ))}
        </div>
      </dib>
    </div>
  );
}

const BatchesJoinedCard = ({ batch, state, setState }) => {
  const [batchById, setBatchById] = useState(null)
  const [reviews, setReviews] = useState(null)
  const [rating, setRating] = useState(null)
  const jwtToken = localStorage.getItem('jwtToken');
  const config = {
    headers: { Authorization: `Bearer ${jwtToken}` },
  };
  useEffect(() => {

    getBatch()

  }, [batch])
  const getBatch = async () => {
    try {
      const res = await get_batches_created_by_guru_by_id(config, batch.id)
      const review = await get_reviews_batch_created_by_guru_by_id(config, batch.id)
      const rating = await get_rating_batch_created_by_guru_by_id(config, batch.id)
      setBatchById(res.data.data)
      setReviews(review.data.data)
      setRating(rating.data.data)
    } catch (error) {

    }
  }
  
  return (
    <div key={batch.id} className='requestDeatailCard'>
      <div className='requestDeatailCardMain'>
        <div className='requestDeatailCardHeader'>
          <div className='section'>
            <div className='icon'>
              <ViolenSvg />
            </div>
            <div className='title'>{batch?.category[0]} for {batch?.name}</div>
            <div className='amount'>
              ${batch?.fees} <span>Per Session</span>
            </div>
          </div>
          <div></div>
        </div>
        <div className='cardStats'>
          <div className='cardStat'>
            <div className='title'>Total Slots</div>
            {batch?.size?.max}
          </div>
          <div className='cardStat'>
            <div className='title'>Vacant Slots</div>{batch?.size?.max - batchById?.enrollments?.length}
          </div>
          <div className='cardStat'>
            <div className='title'>Joined</div>{batchById?.enrollments?.length}
          </div>
        </div>
        <div className='requestDeatailCardBody'>
          <div className='nextBatch'>
            <div className='title'>Next Batch</div>
            {batchById?.nextBatchDate ? format(new Date(batchById?.nextBatchDate), "do MMMM yyyy") : "-----"}
          </div>
          <div className='btn'>
            <button onClick={() => setState({ isPaneOpen: true })}>
              View Detail
            </button>
          </div>
        </div>
      </div>
      <div className='requestDeatailCardFooter'>
        <div className='timings'>
          <div className='days'>
            {batch?.days?.map((day) => (
              <span>{day}</span>
            ))}
          </div>
          <div>{convertedNumTOTime(batch?.time?.start)}-{convertedNumTOTime(batch?.time?.end)}</div>
        </div>
      </div>
      <ExploreRequestDetailSlider
        batch={batchById}
        reviews={reviews}
        state={state}
        rating={rating}
        setState={setState}
      />
    </div>
  );
};

const ExploreRequestDetailSlider = ({ rating,batch, state, setState,reviews }) => {
  const [tab, setTab] = useState(1);
  console.log(rating)

  return (
    <SlidingPane
      className='requestSlider guruRequestSlider '
      overlayClassName='requestSliderOverlay'
      isOpen={state.isPaneOpen}
      hideHeader
      width='30%'
      onRequestClose={() => {
        // triggered on "<" on left top click or on outside click
        setState({ isPaneOpen: false });
      }}
    >
      <div className='requestCardHeader'>
        <div className='icon'>
          <ViolenSvg />
        </div>
        <div className='cardTitle'>
          Looking for {batch?.name} Guru!
          <div className='location'>
            <LocationSvg />
            {batch?.isOnline === true ? "Online" : batch?.location?.fullAddress}
          </div>
        </div>
      </div>
      <div className='sliderTabs'>
        <div
          onClick={() => setTab(1)}
          className={`sliderTab ${tab === 1 && 'selected'}`}
        >
          Basic Info
        </div>
        <div
          onClick={() => setTab(2)}
          className={`sliderTab ${tab === 2 && 'selected'}`}
        >
          Reviews
        </div>
      </div>
      {tab === 1 ? (
        <>
          <div className='container'>
            <div className='user'>
              <div className='img'>
                <img src={batch?.createdBy?.image ?? '/imgs/avatar.svg'} alt='user ' />
              </div>
              <div className='userData'>
                (<span>Hosted by</span>) {batch?.createdBy?.name}
                <div className='ratings'>
                  <StarSvg
                    filled={batch?.avgRating?.rating >= 1 ? true : false}
                  />
                  <StarSvg
                    filled={batch?.avgRating?.rating >= 2 ? true : false}
                  />
                  <StarSvg
                    filled={batch?.avgRating?.rating >= 3 ? true : false}
                  />
                  <StarSvg
                    filled={batch?.avgRating?.rating >= 4 ? true : false}
                  />
                  <StarSvg
                    filled={batch?.avgRating?.rating >= 5 ? true : false}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='rowCol'>
              <div className='rowColTitle'>Next batch</div>
              {batch?.nextBatchDate ? format(new Date(batch?.nextBatchDate), "do MMMM yyyy") : "-----"}
            </div>
            <div className='amounts'>
              <span>Monthly - ${batch?.fees}</span>
              {/* <span>Weekly - $25</span> */}
            </div>
          </div>
          <div className='row'>
            <div className='rowCol grpImgs'>
              <div className='imgs'>
                {batch?.enrollments?.map((item) => (
                  <img src={item?.image ?? '/imgs/avatar.svg'} alt='user' />
                ))}

                {/* <img src='/imgs/1.png' alt='1' />
                <img src='/imgs/2.png' alt='1' />
                <img src='/imgs/3.png' alt='1' /> */}
              </div>
              <div>{batch?.enrollments?.length} Joined</div>
            </div>
            <div className='rowCol'>
              <div className='rowColTitle'>Vacant Slots</div>{batch?.size?.max - batch?.enrollments?.length}
            </div>
            <div className='rowCol'>
              <div className='rowColTitle'>Total Slot Size</div>            {batch?.size?.max}
            </div>
          </div>
          <div className='reviews'>
            <div className='reviews'>
              <div className='rating'>{batch?.avgRating?.rating}</div>
              <div className='outOf'>Out of 5</div>
              <div className='title'>Rating & Reviews</div>
            </div>

           
            <div className='reviewStats'>

            {rating?.batchRatings?.map((item)=> (<div className='reviewRow'>
                <div className='count'>{item.rating}</div>
                <div className='reviewBg'>
                  <div className='reviewCount' style={{ width: `${(item.count / rating?.reviewCount) * 100}`} }></div>
                </div>
              </div>))}
              {/* <div className='reviewRow'>
                <div className='count'>5</div>
                <div className='reviewBg'>
                  <div className='reviewCount' style={{ width: '80%' }}></div>
                </div>
              </div>
              <div className='reviewRow'>
                <div className='count'>4</div>
                <div className='reviewBg'>
                  <div className='reviewCount' style={{ width: '30%' }}></div>
                </div>
              </div>
              <div className='reviewRow'>
                <div className='count'>3</div>
                <div className='reviewBg'>
                  <div className='reviewCount' style={{ width: '20%' }}></div>
                </div>
              </div>
              <div className='reviewRow'>
                <div className='count'>2</div>
                <div className='reviewBg'>
                  <div className='reviewCount' style={{ width: '0%' }}></div>
                </div>
              </div>
              <div className='reviewRow'>
                <div className='count'>1</div>
                <div className='reviewBg'>
                  <div className='reviewCount' style={{ width: '40%' }}></div>
                </div>
              </div> */}
            </div>
          </div>
        </>
      ) : tab === 2 ? (
        <div className='reiewCards'>
          {reviews?.map((review) => {
           return  <RequestCard  data={review}/>
          })}

        </div>
      ) : null}
    </SlidingPane>
  );
};

const RequestCard = ({ id,data }) => {
  return (
    <div key={id} className='requestCard'>
      <div className='requestCardBody'>
        <div className='txnRow'>
          <div className='feedUserData'>
            <div className='userImg'>
              <img src={data?.createdBy?.image??'/imgs/avatar.svg'} alt='user' />
            </div>
            <div>
              Review Posted By <span>{data?.createdBy?.name}</span>
              <div className='ratings'>
              <StarSvg
                    filled={data?.rating >= 1 ? true : false}
                  />
                  <StarSvg
                    filled={data?.rating >= 2 ? true : false}
                  />
                  <StarSvg
                    filled={data?.rating >= 3 ? true : false}
                  />
                  <StarSvg
                    filled={data?.rating >= 4 ? true : false}
                  />
                  <StarSvg
                    filled={data?.rating >= 5 ? true : false}
                  />
              </div>
            </div>
          </div>
          <div>{data?.createdAt?format(new Date (data?.createdAt), 'yyyy-MM-dd'):"----"}</div>
        </div>
        <div className='reviewText'>
          <div>
             {data?.content}
          </div>
        </div>
      </div>
    </div>
  );
};
