import React from 'react';
import { FilterSvg, SearchSvg } from '../../../assets/svg';
import './index.css';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { format } from 'date-fns';

export default function Transactions({ transactions,state, setState }) {
  console.log(transactions)
  return (
    <div className='selectedTab transactions'>
      <div className='tabHeader'>
        <div className='tabTitle'>
          Found <span>{transactions?.length} Transactions</span>
        </div>
        {/* <div className='search'>
          <div className='searchInput'>
            <SearchSvg />
            <input type='text' name='Search' placeholder='Search' />
          </div>
          <div className='filter'>
            <FilterSvg />
            <button>Filter</button>
          </div>
        </div> */}
      </div>
      <dib className='tabBody'>
        <div className='requestCards'>
          {transactions?.map((data) => (
            <RequestCard data={data} state={state} setState={setState} />
          ))}
        </div>
      </dib>
    </div>
  );
}

const RequestCard = ({ data }) => {
 console.log(data)
  return (
    <div key={data?.id} className='requestCard'>
      <div className='requestCardBody'>
        <div className='txnRow'>
          <div className='title'>Credited</div>
          <div>{data?.createdAt&&format(new Date (data?.createdAt), 'MMMM dd, yyyy')}</div>
        </div>
        <div className='txnRow'>
          <div>{data?.batch?.name?`Payed fees for (${data?.batch?.name} batch)`:"----"}</div>
        </div>
        <div className='txnRow'>
          <div className='title'>${data?.amount}</div>
          <div>{data?.transaction_id??"--"}</div>
        </div>
      </div>
    </div>
  );
};
