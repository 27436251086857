import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams, useNavigate, redirect } from 'react-router-dom';
import { authenticate } from '../../API';
import './index.css';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';

// import Skeleton from 'react-loading-skeleton';
// import 'react-loading-skeleton/dist/skeleton.css';

export default function Login({
  userLoggedIn,
  setUserLoggedIn,
  userData,
  setUserData,
}) {
  const navigate = useNavigate();
  const { userEmail } = useParams();

  const rememberedEmail = localStorage.getItem('email');

  const [email, setEmail] = useState(userEmail || rememberedEmail);
  const [pwd, setPwd] = useState('');
  const [revealPwd, setRevealPwd] = useState(false);
  const [errorMessage, setErrorMessage] = useState(true);
const [loading,setLoading]= useState(false)
  const main_data = useRef(null);
 

  useEffect(() => {
    if (userLoggedIn) {
      navigate('/');
    }
  }, []);

  const data = {
    email: email,
    password: pwd,
  };
  const handleLogin = (e) => {
    try {


      if (email !== '' && pwd !== '') {
        setErrorMessage(false);
        setLoading(true)
        authenticate(data).then((res) => {

          if (res?.status === 200 && res?.data?.data?.account?.role === "admin") {
            console.log(res);
            localStorage.setItem('id', res.data.data.id);
            localStorage.setItem('jwtToken', res.data.data.tokens.access.token);
            setUserData(res.data);
            setUserLoggedIn(true);
            setLoading(false)
            navigate('/');
          } else {
            setLoading(false)
            toast.error(res?.data?.message ? res?.data?.message : 'Something Went Wrong', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            ;
          }
        });
      } else if (email === '' || pwd === '') {
        setErrorMessage('Fill the fields');
      }
    } catch (error) {

    }


  };

  return (
    //userData
    <div className='app'>
      <div className='resumeVector_login_left'>
        <img
          src='/imgs/guruxPhnDashboard.png'
          alt='graphic1'
          style={{ maxWidth: '400px' }}
        />
      </div>
      <div className='resumeVector_login_right' style={{ width: 'auto' }}>
        <img
          src='/imgs/guruxPhnDashboard.png'
          alt='graphic2'
          style={{ maxWidth: '400px' }}
        />
      </div>
      <div className='logo'>
        <img src='/imgs/Logo.png' alt='logo' />
      </div>
      <div className='hero'>
        <div className='main'>
          <div ref={main_data}>
            <div className='main_header extraMarginBottom'>
              <div className='main_header_left'>
                <div>
                  <Link to='/login' className='main_header_login'>
                    Login
                  </Link>
                  <div className='main_header_active'></div>
                </div>
              </div>
              <div className='error'>
                <p>{errorMessage}</p>
              </div>
            </div>

            <input
              required
              id='email'
              className='email_login'
              type='email'
              placeholder='john@example.com'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className='pwd_container'>
              <input
                id='password'
                className='password'
                type={revealPwd ? 'text' : 'password'}
                placeholder='Enter password'
                value={pwd}
                onChange={(e) => setPwd(e.target.value)}
              />
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='pwd_eye'
                viewBox='0 0 20 20'
                fill='currentColor'
                title={revealPwd ? 'Show password' : 'Hide password'}
                onClick={() => setRevealPwd((show) => !show)}
                style={{ fill: revealPwd ? '#EC6336' : 'currentcolor' }}
              >
                <path d='M10 12a2 2 0 100-4 2 2 0 000 4z' />
                <path
                  fillRule='evenodd'
                  d='M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z'
                  clipRule='evenodd'
                />
              </svg>
            </div>
            <button className='btn_login' onClick={handleLogin}>
              Login


             {loading&& <ClipLoader size={15} color="white" />}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
