import React, { useEffect, useState } from 'react';
import { ArrowBackBg, LocationSvg, MenuBGSvg } from '../../assets/svg';
import './index.css';
import ExploreRequestsPosted from '../../components/Student/ExploreRequestsPosted';
import Transactions from '../../components/Student/Transactions';
import FeedbacksPosted from '../../components/Student/FeedbacksPosted';
import { useNavigate, useParams } from 'react-router-dom';
import BatchesCreated from '../../components/Gurus/BatchesCreated';
import {
  get_batches_created_by_guru,
  get_batches_reviews_for_guru,
  get_guru_ratings,
  get_requirements_created_by_student,
  get_transactions,
  get_user_by_id,
} from '../../API';
import moment from 'moment';

export default function GuruDetail({ jwtToken }) {
  const [tab, setTab] = useState(2);
  const { id } = useParams();
  const navigate = useNavigate();
  const [state, setState] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false,
  });

  const [errMsg, setErrMsg] = useState('');
  const [user, setUser] = useState(null);
  const [requirements, setRequirements] = useState(null);
  const [batchesCreated, setBatchesCreated] = useState(null);
  const [reviews, setReviews] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [ratings, setRatings] = useState(null);
  

  const config = {
    headers: { Authorization: `Bearer ${jwtToken}` },
  };

  const formatDate = (date) => {
    return moment(date).format('DD-MMM-yyyy');
  };

  useEffect(() => {
    get_guru_ratings(config, id)
      .then((res) => {
        if (res?.status === 200 && res?.data?.success) {
          const updatedData = []
          const data = res.data.data
          for (let index = 0; index < 5; index++) {
            const filtered = data.ratings.filter(item => item.rating === index + 1)[0]
            if (filtered) {

              updatedData.push(filtered)


            } else {
              updatedData.push({
                count: 0,
                rating: index + 1,
              })

            }


          }

          data.ratings = updatedData.reverse()
          setRatings(data);
        } else if (res?.status === 401) {
          setErrMsg(res?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    get_user_by_id(config, id)
      .then((res) => {
        if (res?.status === 200 && res?.data?.success) {
          setUser(res.data.data);
        } else if (res?.status === 401) {
          setErrMsg(res?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    get_requirements_created_by_student(config, id)
      .then((res) => {
        if (res?.status === 200 && res?.data?.success) {
          setRequirements(res.data.data);
        } else if (res?.status === 401) {
          setErrMsg(res?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    get_batches_created_by_guru(config, id)
      .then((res) => {
        // console.log(res);
        if (res?.status === 200 && res?.data?.success) {
          setBatchesCreated(res.data.data);
        } else if (res?.status === 401) {
          setErrMsg(res?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    get_batches_reviews_for_guru(config, id)
      .then((res) => {
        // console.log(res);
        if (res?.status === 200 && res?.data?.success) {
          setReviews(res.data.data);
        } else if (res?.status === 401) {
          setErrMsg(res?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
      get_transactions(config, id)
      .then((res) => {
        // console.log(res);
        if (res?.status === 200 && res?.data?.success) {
          setTransactions(res.data.data.data);
        } else if (res?.status === 401) {
          setErrMsg(res?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
 

  return (
    <div className='page students studentDetails guruDetails'>
      <div className='studentsHeader'>
        <div className='pageNmae'>
          <div>Guru Detail</div>
          <div>View Guru Details</div>
        </div>
      </div>
      <div className='studentDetail'>
        <div className='studentProfileCard'>
          <div className='profileCardHeader'>
            <div className='back' onClick={() => navigate(-1)}>
              <ArrowBackBg />
            </div>
            <div className='menu'>
              <MenuBGSvg />
            </div>
          </div>
          <div className='profileUserDetail'>
            <div className='img'>
              {<img src={user?.user?.image ?? '/imgs/avatar.svg'} alt='user' />}
            </div>
            <div className='userName'>{user?.user?.name ?? '-'}</div>
            <div className='location'>
              {/* <LocationSvg /> */}
              {/* <span>{user?.user?.location ?? '-'}</span> */}
            </div>
          </div>
          <div className='userAppData'>
            <div className='row'>
              <div className='rowTitle'>Joined Date</div>
              <div>{formatDate(user?.user?.createdAt)}</div>
            </div>
            <div className='row'>
              <div className='rowTitle'>Batches Created</div>
              <div>{user?.batchesCount}</div>
            </div>
            <div className='row'>
              <div className='rowTitle'>Earned till date</div>
              <div>${user?.fees ?? 0}</div>
            </div>
            {/* <div className='row'>
              <div className='rowTitle'>Hours Spent on app</div>
              <div>{user?.timeSpent}mins</div>
            </div> */}
          </div>

          <div className='reviews'>
            <div className='reviews'>
              <div className='rating'>{ratings?.avgRating?.rating??0}</div>
              <div className='outOf'>Out of 5</div>
              <div className='title'>Rating & Reviews</div>
            </div>
            <div className='reviewStats'>
             {ratings?.ratings?.map((item)=> (<div className='reviewRow'>
                <div className='count'>{item.rating}</div>
                <div className='reviewBg'>
                  <div className='reviewCount' style={{ width: `${(item.count / ratings?.avgRating?.totalCount) * 100}`} }></div>
                </div>
              </div>))}
              {/* <div className='reviewRow'>
                <div className='count'>4</div>
                <div className='reviewBg'>
                  <div className='reviewCount' style={{ width: '30%' }}></div>
                </div>
              </div> */}
              {/* <div className='reviewRow'>
                <div className='count'>3</div>
                <div className='reviewBg'>
                  <div className='reviewCount' style={{ width: '20%' }}></div>
                </div>
              </div> */}
              {/* <div className='reviewRow'>
                <div className='count'>2</div>
                <div className='reviewBg'>
                  <div className='reviewCount' style={{ width: '0%' }}></div>
                </div>
              </div> */}
              {/* <div className='reviewRow'>
                <div className='count'>1</div>
                <div className='reviewBg'>
                  <div className='reviewCount' style={{ width: '40%' }}></div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className='studentDetailCard'>
          <div className='studentDetailCardTabs'>
            {/* <div
              onClick={() => setTab(1)}
              className={`studentDetailCardTab ${tab === 1 && 'selected'}`}
            >
              Explore Requests Posted
            </div> */}
            <div
              onClick={() => setTab(2)}
              className={`studentDetailCardTab ${tab === 2 && 'selected'}`}
            >
              Batches Created
            </div>
            <div
              onClick={() => setTab(3)}
              className={`studentDetailCardTab ${tab === 3 && 'selected'}`}
            >
              Transactions
            </div>
            <div
              onClick={() => setTab(4)}
              className={`studentDetailCardTab ${tab === 4 && 'selected'}`}
            >
              Reviews
            </div>
          </div>
          {tab === 1 ? (
            <ExploreRequestsPosted
              requirements={requirements}
              state={state}
              setState={setState}
            />
          ) : tab === 2 ? (
            <BatchesCreated
              batchesCreated={batchesCreated}
              state={state}
              setState={setState}
            />
          ) : tab === 3 ? (
            <Transactions transactions={transactions} state={state} setState={setState} />
          ) : tab === 4 ? (
            <FeedbacksPosted
              reviews={reviews}
              state={state}
              setState={setState}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}
