const GuruzLogo = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24.216'
      height='54.604'
      viewBox='0 0 24.216 54.604'
    >
      <g
        id='Group_8506'
        data-name='Group 8506'
        transform='translate(-33.018 -11.516)'
      >
        <g
          id='Group_8505'
          data-name='Group 8505'
          transform='translate(33.018 28.752)'
        >
          <path
            id='Subtraction_1'
            data-name='Subtraction 1'
            d='M12.108,24.216a12.2,12.2,0,0,1-2.44-.246,12.041,12.041,0,0,1-4.329-1.822A12.143,12.143,0,0,1,.951,16.821a12.047,12.047,0,0,1-.705-2.273,12.226,12.226,0,0,1,0-4.88A12.041,12.041,0,0,1,2.068,5.338,12.143,12.143,0,0,1,7.395.951,12.047,12.047,0,0,1,9.668.246a12.226,12.226,0,0,1,4.88,0,12.042,12.042,0,0,1,4.329,1.822,12.143,12.143,0,0,1,4.387,5.327,12.047,12.047,0,0,1,.706,2.273,12.226,12.226,0,0,1,0,4.88,12.041,12.041,0,0,1-1.822,4.329,12.143,12.143,0,0,1-5.327,4.387,12.047,12.047,0,0,1-2.273.706A12.2,12.2,0,0,1,12.108,24.216Zm0-16.144a4.023,4.023,0,1,0,1.571.317A4.011,4.011,0,0,0,12.108,8.072Z'
            transform='translate(0 0.036)'
            fill='#fff'
          />
          <path
            id='Rectangle_1'
            data-name='Rectangle 1'
            d='M0,0H11.689a0,0,0,0,1,0,0V0A7.793,7.793,0,0,1,3.9,7.793H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z'
            transform='translate(12.246)'
            fill='#fff'
          />
          <path
            id='Subtraction_3'
            data-name='Subtraction 3'
            d='M8.072,12.108H0a12.194,12.194,0,0,1,.246-2.44A12.042,12.042,0,0,1,2.068,5.338,12.143,12.143,0,0,1,7.395.951,12.049,12.049,0,0,1,9.668.246a12.225,12.225,0,0,1,4.88,0,12.042,12.042,0,0,1,4.329,1.822,12.142,12.142,0,0,1,4.387,5.327,12.049,12.049,0,0,1,.705,2.273,12.194,12.194,0,0,1,.246,2.44H16.144a4.036,4.036,0,1,0-8.072,0Z'
            transform='translate(0 25.26)'
            fill='#fff'
          />
        </g>
        <path
          id='Polygon_3'
          data-name='Polygon 3'
          d='M8.906,0l8.906,12.246H0Z'
          transform='translate(36.583 11.516)'
          fill='#fff'
        />
      </g>
    </svg>
  );
};
const DashboardSvg = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='15.5'
      height='20'
      viewBox='0 0 15.5 20'
    >
      <g
        id='Icon_feather-bar-chart-2'
        data-name='Icon feather-bar-chart-2'
        transform='translate(-8 -5)'
      >
        <path
          id='Path_960'
          data-name='Path 960'
          d='M27,30V15'
          transform='translate(-4.5 -6)'
          fill='none'
          stroke='#fff'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
        <path
          id='Path_961'
          data-name='Path 961'
          d='M18,24V6'
          transform='translate(-2.25)'
          fill='none'
          stroke='#fff'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
        <path
          id='Path_962'
          data-name='Path 962'
          d='M9,30V21'
          transform='translate(0 -6)'
          fill='none'
          stroke='#fff'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
      </g>
    </svg>
  );
};
const PeopleSvg = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='23.8'
      height='19.865'
      viewBox='0 0 23.8 19.865'
    >
      <g
        id='Icon_feather-users'
        data-name='Icon feather-users'
        transform='translate(-0.6 -3.535)'
      >
        <path
          id='Path_963'
          data-name='Path 963'
          d='M17.5,28.5v-2a4,4,0,0,0-4-4h-8a4,4,0,0,0-4,4v2'
          transform='translate(0 -6)'
          fill='none'
          stroke='#fff'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.8'
        />
        <path
          id='Path_964'
          data-name='Path 964'
          d='M15.5,8.5a4,4,0,1,1-4-4,4,4,0,0,1,4,4Z'
          transform='translate(-2 0)'
          fill='none'
          stroke='#fff'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.8'
        />
        <path
          id='Path_965'
          data-name='Path 965'
          d='M33,28.565v-2a4,4,0,0,0-3-3.87'
          transform='translate(-9.5 -6.065)'
          fill='none'
          stroke='#fff'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.8'
        />
        <path
          id='Path_966'
          data-name='Path 966'
          d='M24,4.695a4,4,0,0,1,0,7.75'
          transform='translate(-7.5 -0.065)'
          fill='none'
          stroke='#fff'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.8'
        />
      </g>
    </svg>
  );
};
const WistleSvg = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='22.412'
      height='25.505'
      viewBox='0 0 22.412 25.505'
    >
      <g
        id='Group_8532'
        data-name='Group 8532'
        transform='translate(266.656 -13597.881)'
      >
        <path
          id='Path_954'
          data-name='Path 954'
          d='M213.862,242.146a5.931,5.931,0,1,0-.727-8.356A5.977,5.977,0,0,0,213.862,242.146Zm.1-7.681a4.9,4.9,0,1,1,.571,6.9A4.872,4.872,0,0,1,213.966,234.465Z'
          transform='translate(-473.65 13376.732)'
          fill='#fff'
          stroke='#fff'
          strokeWidth='0.5'
        />
        <path
          id='Path_955'
          data-name='Path 955'
          d='M305.206,287.1a2.541,2.541,0,0,1,2.491.415.515.515,0,1,0,.675-.779,3.488,3.488,0,0,0-3.529-.571.54.54,0,0,0-.311.675.567.567,0,0,0,.675.26Z'
          transform='translate(-562.088 13324.981)'
          fill='#fff'
          stroke='#fff'
          strokeWidth='0.5'
        />
        <path
          id='Path_956'
          data-name='Path 956'
          d='M207.861,89.77l2.958,1.3a.511.511,0,1,0,.415-.934l-2.958-1.349a.515.515,0,0,0-.675.26A.53.53,0,0,0,207.861,89.77Z'
          transform='translate(-469.622 13513.021)'
          fill='#fff'
          stroke='#fff'
          strokeWidth='0.5'
        />
        <path
          id='Path_957'
          data-name='Path 957'
          d='M240.188,27.708a.515.515,0,1,0,.779-.675l-2.232-2.387a.515.515,0,0,0-.779.675Z'
          transform='translate(-498.523 13574.305)'
          fill='#fff'
          stroke='#fff'
          strokeWidth='0.5'
        />
        <path
          id='Path_958'
          data-name='Path 958'
          d='M307.965,14.083a.525.525,0,0,0,.986-.363l-1.194-3.01a.5.5,0,0,0-.674-.311.54.54,0,0,0-.311.675Z'
          transform='translate(-564.225 13587.773)'
          fill='#fff'
          stroke='#fff'
          strokeWidth='0.5'
        />
        <path
          id='Path_959'
          data-name='Path 959'
          d='M117.584,36.834a2.505,2.505,0,0,0,1.349-1.557A8.817,8.817,0,0,0,133.1,25.624c-.519-1.349-1.3-3.425.623-5.553l2.439-2.8a.544.544,0,0,0,.052-.623l-2.491-3.737a.51.51,0,0,0-.727-.156l-6.747,4.515a.51.51,0,0,0-.156.727l.727,1.09-1.557,1.038-1.764-.778a.613.613,0,0,0-.519.052l-3.322,2.232c-.052,0-.052.052-.1.052a8.754,8.754,0,0,0-2.7,10.691,3.348,3.348,0,0,0-1.038.156,2.316,2.316,0,0,0-.727,3.789,2.219,2.219,0,0,0,2.491.519Zm2.7-14.376.052-.052,3.01-2.024,1.713.727a.613.613,0,0,0,.519-.052l2.232-1.505a.51.51,0,0,0,.156-.727l-.727-1.09,5.865-3.84,1.972,2.958-2.18,2.491c-2.284,2.595-1.349,5.19-.83,6.643a7.767,7.767,0,1,1-11.781-3.529Zm-4.1,11.054a1.275,1.275,0,0,1,1.4.26,1.338,1.338,0,0,1,.363,1.142,1.292,1.292,0,0,1-.779.934,1.378,1.378,0,0,1-1.4-.26,1.289,1.289,0,0,1,.415-2.076Z'
          transform='translate(-380.797 13585.572)'
          fill='#fff'
          stroke='#fff'
          strokeWidth='0.5'
        />
      </g>
    </svg>
  );
};
const MoneySvg = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='21.817'
      height='26.5'
      viewBox='0 0 21.817 26.5'
    >
      <path
        id='Path_971'
        data-name='Path 971'
        d='M135.427,23.748a2.452,2.452,0,0,1,1.98-2.412v-.52a.67.67,0,1,1,1.34,0v.536a2.639,2.639,0,0,1,1.777,1.536.67.67,0,0,1-1.235.517,1.308,1.308,0,0,0-1.209-.8,1.191,1.191,0,0,0-1.31,1.147c0,.206,0,.828,1.461,1.163h.022c2.144.5,2.479,1.758,2.479,2.463h0a2.452,2.452,0,0,1-1.981,2.412v.536a.67.67,0,1,1-1.34,0v-.536a2.639,2.639,0,0,1-1.777-1.535.67.67,0,1,1,1.235-.517,1.311,1.311,0,0,0,1.211.8,1.191,1.191,0,0,0,1.31-1.147c0-.206,0-.828-1.461-1.163h-.021c-2.16-.514-2.481-1.771-2.481-2.479Zm4.824-9.2h0a.67.67,0,0,0,.925-.169l2.307-3.286h0a.673.673,0,0,0-.123-.9,8.48,8.48,0,0,0-10.567.008.67.67,0,0,0-.121.9l2.308,3.28a.67.67,0,0,0,1.1-.769l-1.935-2.752a7.156,7.156,0,0,1,7.874,0l-1.935,2.758a.67.67,0,0,0,.163.933ZM129.054,27.6a15.232,15.232,0,0,1,5.416-11.609.67.67,0,0,1,.431-.158h6.349a.67.67,0,0,1,.431.158A15.233,15.233,0,0,1,147.1,27.6v1.905l.206.1a2.495,2.495,0,0,1-1.072,4.746H129.917a2.5,2.5,0,0,1-1.072-4.746l.206-.1Zm-.268,4.51a1.147,1.147,0,0,0,1.126.9h16.321a1.155,1.155,0,0,0,.5-2.195l-.587-.268a.67.67,0,0,1-.378-.622V27.6h0A13.9,13.9,0,0,0,141,17.174h-5.853a13.893,13.893,0,0,0-4.757,10.43v2.326a.67.67,0,0,1-.381.6l-.587.268a1.149,1.149,0,0,0-.635,1.31Z'
        transform='translate(-127.167 -8.102)'
        fill='#fff'
        stroke='#fff'
        strokeWidth='0.5'
      />
    </svg>
  );
};
const SettingsSvg = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24.8'
      height='24.8'
      viewBox='0 0 24.8 24.8'
    >
      <g
        id='Icon_feather-settings'
        data-name='Icon feather-settings'
        transform='translate(-0.6 -0.6)'
      >
        <path
          id='Path_972'
          data-name='Path 972'
          d='M19.773,16.636A3.136,3.136,0,1,1,16.636,13.5,3.136,3.136,0,0,1,19.773,16.636Z'
          transform='translate(-3.636 -3.636)'
          fill='none'
          stroke='#fff'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.8'
        />
        <path
          id='Path_973'
          data-name='Path 973'
          d='M20.736,16.136a1.725,1.725,0,0,0,.345,1.9l.063.063a2.092,2.092,0,1,1-2.959,2.959L18.123,21a1.739,1.739,0,0,0-2.948,1.234v.178a2.091,2.091,0,0,1-4.182,0v-.094a1.725,1.725,0,0,0-1.129-1.579,1.725,1.725,0,0,0-1.9.345l-.063.063A2.092,2.092,0,1,1,4.94,18.185L5,18.123a1.739,1.739,0,0,0-1.234-2.948H3.591a2.091,2.091,0,0,1,0-4.182h.094A1.725,1.725,0,0,0,5.264,9.864a1.725,1.725,0,0,0-.345-1.9L4.856,7.9A2.092,2.092,0,1,1,7.815,4.94L7.877,5a1.725,1.725,0,0,0,1.9.345h.084a1.725,1.725,0,0,0,1.045-1.579V3.591a2.091,2.091,0,0,1,4.182,0v.094a1.739,1.739,0,0,0,2.948,1.234l.063-.063A2.092,2.092,0,1,1,21.06,7.815L21,7.877a1.725,1.725,0,0,0-.345,1.9v.084a1.725,1.725,0,0,0,1.579,1.045h.178a2.091,2.091,0,0,1,0,4.182h-.094a1.725,1.725,0,0,0-1.579,1.045Z'
          transform='translate(0 0)'
          fill='none'
          stroke='#fff'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.8'
        />
      </g>
    </svg>
  );
};
const SearchSvg = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20.414'
      height='20.414'
      viewBox='0 0 20.414 20.414'
    >
      <g
        id='Icon_feather-search'
        data-name='Icon feather-search'
        transform='translate(-3.5 -3.5)'
        opacity='0.4'
      >
        <path
          id='Path_952'
          data-name='Path 952'
          d='M20.5,12.5a8,8,0,1,1-8-8,8,8,0,0,1,8,8Z'
          transform='translate(0 0)'
          fill='none'
          stroke='#000'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
        <path
          id='Path_953'
          data-name='Path 953'
          d='M29.325,29.325l-4.35-4.35'
          transform='translate(-6.825 -6.825)'
          fill='none'
          stroke='#000'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
      </g>
    </svg>
  );
};
const ArrowDown = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='17.49'
      height='10'
      viewBox='0 0 17.49 10'
    >
      <path
        id='Icon_ionic-ios-arrow-down'
        data-name='Icon ionic-ios-arrow-down'
        d='M14.936,18.232l6.613-6.618a1.245,1.245,0,0,1,1.765,0,1.26,1.26,0,0,1,0,1.77l-7.493,7.5a1.248,1.248,0,0,1-1.724.036l-7.545-7.53a1.25,1.25,0,0,1,1.765-1.77Z'
        transform='translate(-6.188 -11.246)'
        fill='#494949'
      />
    </svg>
  );
};
const WistleDashboardSvg = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='35.553'
      height='40.508'
      viewBox='0 0 35.553 40.508'
    >
      <g
        id='Group_8508'
        data-name='Group 8508'
        transform='translate(266.66 -13597.88)'
      >
        <path
          id='Path_954'
          data-name='Path 954'
          d='M215.12,248.44a9.488,9.488,0,1,0-1.162-13.368A9.563,9.563,0,0,0,215.12,248.44Zm.166-12.288a7.835,7.835,0,1,1,.913,11.043A7.8,7.8,0,0,1,215.287,236.151Z'
          transform='translate(-470.944 13382.885)'
          fill='#f5a041'
          stroke='#f5a041'
          strokeWidth='0.5'
        />
        <path
          id='Path_955'
          data-name='Path 955'
          d='M305.631,287.805a4.066,4.066,0,0,1,3.986.664.824.824,0,1,0,1.079-1.246,5.581,5.581,0,0,0-5.646-.913.863.863,0,0,0-.5,1.079.907.907,0,0,0,1.079.415Z'
          transform='translate(-556.804 13332.642)'
          fill='#f5a041'
          stroke='#f5a041'
          strokeWidth='0.5'
        />
        <path
          id='Path_956'
          data-name='Path 956'
          d='M208.054,90.386l4.733,2.076a.818.818,0,0,0,.664-1.495l-4.732-2.159a.824.824,0,0,0-1.079.415A.848.848,0,0,0,208.054,90.386Z'
          transform='translate(-467.033 13515.198)'
          fill='#f5a041'
          stroke='#f5a041'
          strokeWidth='0.5'
        />
        <path
          id='Path_957'
          data-name='Path 957'
          d='M241.593,29.643a.824.824,0,0,0,1.246-1.079l-3.57-3.819a.824.824,0,1,0-1.246,1.079Z'
          transform='translate(-495.092 13574.696)'
          fill='#f5a041'
          stroke='#f5a041'
          strokeWidth='0.5'
        />
        <path
          id='Path_958'
          data-name='Path 958'
          d='M308.7,16.317a.841.841,0,1,0,1.577-.581l-1.91-4.816a.8.8,0,0,0-1.079-.5.863.863,0,0,0-.5,1.079Z'
          transform='translate(-558.878 13587.773)'
          fill='#f5a041'
          stroke='#f5a041'
          strokeWidth='0.5'
        />
        <path
          id='Path_959'
          data-name='Path 959'
          d='M119.494,51.332a4.008,4.008,0,0,0,2.159-2.491A14.107,14.107,0,0,0,144.32,33.4c-.83-2.159-2.076-5.48,1-8.884l3.9-4.484a.871.871,0,0,0,.083-1l-3.986-5.978a.817.817,0,0,0-1.162-.249L133.36,20.029a.817.817,0,0,0-.249,1.162l1.162,1.744-2.491,1.66-2.823-1.245a.982.982,0,0,0-.83.083L122.815,27c-.083,0-.083.083-.166.083a14,14,0,0,0-4.318,17.1,5.356,5.356,0,0,0-1.66.249,3.7,3.7,0,0,0-1.162,6.061,3.55,3.55,0,0,0,3.985.83Zm4.317-23,.083-.083,4.816-3.238,2.74,1.162a.981.981,0,0,0,.83-.083l3.57-2.408a.817.817,0,0,0,.249-1.162l-1.162-1.744,9.382-6.144,3.155,4.733-3.487,3.986c-3.653,4.152-2.159,8.3-1.328,10.628a12.426,12.426,0,1,1-18.848-5.646Zm-6.559,17.685a2.04,2.04,0,0,1,2.242.415,2.14,2.14,0,0,1,.581,1.827,2.066,2.066,0,0,1-1.246,1.495,2.2,2.2,0,0,1-2.242-.415,2.062,2.062,0,0,1,.664-3.321Z'
          transform='translate(-380.797 13585.639)'
          fill='#f5a041'
          stroke='#f5a041'
          strokeWidth='0.5'
        />
      </g>
    </svg>
  );
};
const PeopleDashboardSvg = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='35.6'
      height='29.687'
      viewBox='0 0 35.6 29.687'
    >
      <g
        id='Icon_feather-users'
        data-name='Icon feather-users'
        transform='translate(-0.2 -3.113)'
      >
        <path
          id='Path_963'
          data-name='Path 963'
          d='M25.5,31.5v-3a6,6,0,0,0-6-6H7.5a6,6,0,0,0-6,6v3'
          fill='none'
          stroke='#f5a041'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2.6'
        />
        <path
          id='Path_964'
          data-name='Path 964'
          d='M19.5,10.5a6,6,0,1,1-6-6,6,6,0,0,1,6,6Z'
          fill='none'
          stroke='#f5a041'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2.6'
        />
        <path
          id='Path_965'
          data-name='Path 965'
          d='M34.5,31.5v-3A6,6,0,0,0,30,22.7'
          fill='none'
          stroke='#f5a041'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2.6'
        />
        <path
          id='Path_966'
          data-name='Path 966'
          d='M24,4.7A6,6,0,0,1,24,16.32'
          fill='none'
          stroke='#f5a041'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2.6'
        />
      </g>
    </svg>
  );
};
const MoneyDashboardSvg = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='30.016'
      height='36.5'
      viewBox='0 0 30.016 36.5'
    >
      <path
        id='Path_967'
        data-name='Path 967'
        d='M138.5,29.669a3.395,3.395,0,0,1,2.742-3.34v-.72a.928.928,0,1,1,1.855,0v.742a3.655,3.655,0,0,1,2.46,2.126.928.928,0,0,1-1.711.716,1.811,1.811,0,0,0-1.674-1.113,1.648,1.648,0,0,0-1.814,1.588c0,.286,0,1.147,2.022,1.61h.03c2.969.694,3.432,2.434,3.432,3.41h0a3.4,3.4,0,0,1-2.742,3.34v.742a.928.928,0,1,1-1.855,0V38.03a3.654,3.654,0,0,1-2.46-2.126.928.928,0,1,1,1.71-.716,1.815,1.815,0,0,0,1.677,1.113,1.648,1.648,0,0,0,1.814-1.588c0-.286,0-1.147-2.022-1.61h-.03c-2.991-.712-3.436-2.453-3.436-3.432Zm6.679-12.732h0a.928.928,0,0,0,1.28-.234l3.195-4.549h0a.931.931,0,0,0-.171-1.25,11.742,11.742,0,0,0-14.631.011.928.928,0,0,0-.167,1.247l3.2,4.542a.928.928,0,0,0,1.518-1.065l-2.679-3.811a9.908,9.908,0,0,1,10.9,0l-2.679,3.818a.928.928,0,0,0,.226,1.291Zm-15.5,18.068a21.09,21.09,0,0,1,7.5-16.074.928.928,0,0,1,.6-.219h8.79a.928.928,0,0,1,.6.219,21.091,21.091,0,0,1,7.5,16.074v2.638l.286.137a3.455,3.455,0,0,1-1.484,6.572H130.875a3.455,3.455,0,0,1-1.484-6.572l.286-.137Zm-.371,6.245a1.588,1.588,0,0,0,1.558,1.247h22.6a1.6,1.6,0,0,0,.69-3.039l-.813-.371a.928.928,0,0,1-.523-.861V35.005h0a19.239,19.239,0,0,0-6.594-14.438h-8.1a19.237,19.237,0,0,0-6.586,14.442V38.23a.928.928,0,0,1-.527.835l-.813.371a1.591,1.591,0,0,0-.879,1.814Z'
        transform='translate(-127.164 -8.102)'
        fill='#4cabfd'
        stroke='#4cabfd'
        strokeWidth='0.5'
      />
    </svg>
  );
};
const StarSvg = ({ filled }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='14.343'
      height='13.728'
      viewBox='0 0 14.343 13.728'
    >
      <path
        id='Icon_awesome-star'
        data-name='Icon awesome-star'
        d='M7.843.477,6.093,4.026,2.176,4.6A.858.858,0,0,0,1.7,6.061L4.535,8.822l-.67,3.9a.857.857,0,0,0,1.244.9l3.5-1.842,3.5,1.842a.858.858,0,0,0,1.244-.9l-.67-3.9,2.834-2.761A.858.858,0,0,0,15.049,4.6l-3.917-.571L9.382.477A.859.859,0,0,0,7.843.477Z'
        transform='translate(-1.441 0.001)'
        fill={filled ? '#ffcd27' : '#d3d4d5'}
      />
    </svg>
  );
};
const FilterSvg = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24.022'
      height='21.8'
      viewBox='0 0 24.022 21.8'
    >
      <path
        id='Icon_feather-filter'
        data-name='Icon feather-filter'
        d='M25.222,4.5H3l8.889,10.511v7.267L16.333,24.5V15.011Z'
        transform='translate(-2.1 -3.6)'
        fill='none'
        stroke='#fff'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.8'
      />
    </svg>
  );
};
const EyeSvg = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='17.801'
      height='12'
      viewBox='0 0 17.801 12'
    >
      <path
        id='Icon_ionic-md-eye'
        data-name='Icon ionic-md-eye'
        d='M11.151,7.383a9.571,9.571,0,0,0-8.9,6,9.6,9.6,0,0,0,17.8,0A9.571,9.571,0,0,0,11.151,7.383Zm0,10a4,4,0,1,1,4.045-4A4.035,4.035,0,0,1,11.151,17.384Zm0-6.4a2.4,2.4,0,1,0,2.428,2.4A2.422,2.422,0,0,0,11.151,10.983Z'
        transform='translate(-2.25 -7.383)'
        fill='#555a5c'
      />
    </svg>
  );
};
const GreenArrowUp = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      viewBox='0 0 16 16'
    >
      <path
        id='Icon_awesome-arrow-alt-circle-up'
        data-name='Icon awesome-arrow-alt-circle-up'
        d='M.563,8.563a8,8,0,1,1,8,8A8,8,0,0,1,.563,8.563ZM9.982,12.3V8.563h2.287a.387.387,0,0,0,.274-.661L8.837,4.214a.384.384,0,0,0-.545,0L4.582,7.9a.387.387,0,0,0,.274.661H7.143V12.3a.388.388,0,0,0,.387.387H9.595A.388.388,0,0,0,9.982,12.3Z'
        transform='translate(-0.563 -0.563)'
        fill='#14a81a'
      />
    </svg>
  );
};
const RedArrowDown = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      viewBox='0 0 16 16'
    >
      <path
        id='Icon_awesome-arrow-alt-circle-up'
        data-name='Icon awesome-arrow-alt-circle-up'
        d='M.563,8.563a8,8,0,1,0,8-8A8,8,0,0,0,.563,8.563ZM9.982,4.821V8.563h2.287a.387.387,0,0,1,.274.661L8.837,12.911a.384.384,0,0,1-.545,0L4.582,9.224a.387.387,0,0,1,.274-.661H7.143V4.821a.388.388,0,0,1,.387-.387H9.595A.388.388,0,0,1,9.982,4.821Z'
        transform='translate(-0.563 -0.563)'
        fill='#e61616'
      />
    </svg>
  );
};
const ArrowBackBg = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='35'
      height='35'
      viewBox='0 0 35 35'
    >
      <g
        id='Group_8580'
        data-name='Group 8580'
        transform='translate(-1759 -343)'
      >
        <circle
          id='Ellipse_218'
          data-name='Ellipse 218'
          cx='17.5'
          cy='17.5'
          r='17.5'
          transform='translate(1759 343)'
          fill='#2b2b2b'
          opacity='0.2'
          style={{ mixBlendMode: 'multiply', isolation: 'isolate' }}
        />
        <g
          id='Icon_feather-arrow-left'
          data-name='Icon feather-arrow-left'
          transform='translate(1761 345)'
        >
          <path
            id='Path_980'
            data-name='Path 980'
            d='M23.5,18H7.5'
            transform='translate(0 -2.5)'
            fill='none'
            stroke='#fff'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='2'
          />
          <path
            id='Path_981'
            data-name='Path 981'
            d='M15.5,23.5l-8-8,8-8'
            fill='none'
            stroke='#fff'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='2'
          />
        </g>
      </g>
    </svg>
  );
};
const MenuBGSvg = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='35'
      height='35'
      viewBox='0 0 35 35'
    >
      <g
        id='Group_8547'
        data-name='Group 8547'
        transform='translate(-1759 -343)'
      >
        <circle
          id='Ellipse_218'
          data-name='Ellipse 218'
          cx='17.5'
          cy='17.5'
          r='17.5'
          transform='translate(1759 343)'
          fill='#2b2b2b'
          opacity='0.2'
          style={{ mixBlendMode: 'multiply', isolation: 'isolate' }}
        />
        <path
          id='Icon_ionic-md-more'
          data-name='Icon ionic-md-more'
          d='M19.188,8.75a2,2,0,1,0-2,2A2.006,2.006,0,0,0,19.188,8.75Zm0,12a2,2,0,1,0-2,2A2.006,2.006,0,0,0,19.188,20.75Zm0-6a2,2,0,1,0-2,2A2.006,2.006,0,0,0,19.188,14.75Z'
          transform='translate(1759.813 346.25)'
          fill='#fff'
        />
      </g>
    </svg>
  );
};
const LocationSvg = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='11.2'
      height='16'
      viewBox='0 0 11.2 16'
    >
      <path
        id='Icon_material-location-on'
        data-name='Icon material-location-on'
        d='M13.1,3A5.6,5.6,0,0,0,7.5,8.6c0,4.2,5.6,10.4,5.6,10.4s5.6-6.2,5.6-10.4A5.6,5.6,0,0,0,13.1,3Zm0,7.6a2,2,0,1,1,2-2A2,2,0,0,1,13.1,10.6Z'
        transform='translate(-7.5 -3)'
        opacity='0.4'
      />
    </svg>
  );
};
const ViolenSvg = () => {
  return (
    <svg
      id='noun-violin-2935805'
      xmlns='http://www.w3.org/2000/svg'
      width='23.81'
      height='23.78'
      viewBox='0 0 23.81 23.78'
    >
      <path
        id='Path_61'
        data-name='Path 61'
        d='M71.531,13.609A5.854,5.854,0,0,1,74.943,11.9l.754.754a1.487,1.487,0,1,0,2.1-2.1l-.516-.516a5.028,5.028,0,0,1,.952-1.309,4.639,4.639,0,0,1,5.633-.754l5.395-5.395-.119-.159a.814.814,0,0,1,0-1.15L90.175.241a.814.814,0,0,1,1.15,0l2.023,2.023a.814.814,0,0,1,0,1.15L92.317,4.446a.814.814,0,0,1-1.15,0l-.159-.159L85.613,9.682a4.7,4.7,0,0,1-.754,5.672,4.114,4.114,0,0,1-1.309.912l-.516-.476a1.508,1.508,0,0,0-2.142,0,1.535,1.535,0,0,0,0,2.1l.793.754a6.222,6.222,0,0,1-1.706,3.411,6.034,6.034,0,0,1-8.449,0,5.961,5.961,0,0,1,0-8.449Z'
        transform='translate(-69.776 -0.003)'
        fill='#4cabfd'
        fill-rule='evenodd'
      />
    </svg>
  );
};
const GuruzSvg = () => {
  return (
    <svg
      id='Group_8596'
      data-name='Group 8596'
      xmlns='http://www.w3.org/2000/svg'
      width='22'
      height='22'
      viewBox='0 0 22 22'
    >
      <path
        id='verified'
        d='M21.536,9.705,20.052,7.864a2.063,2.063,0,0,1-.426-1.058L19.365,4.47a2.075,2.075,0,0,0-1.828-1.828L15.2,2.381a2.093,2.093,0,0,1-1.072-.453L12.288.443a2.066,2.066,0,0,0-2.583,0L7.864,1.928a2.063,2.063,0,0,1-1.058.426L4.47,2.615A2.074,2.074,0,0,0,2.642,4.442L2.381,6.778A2.093,2.093,0,0,1,1.928,7.85L.443,9.691a2.066,2.066,0,0,0,0,2.583l1.484,1.841a2.063,2.063,0,0,1,.426,1.058l.261,2.336a2.074,2.074,0,0,0,1.828,1.828l2.336.261a2.093,2.093,0,0,1,1.072.453l1.841,1.484a2.066,2.066,0,0,0,2.583,0l1.841-1.484a2.063,2.063,0,0,1,1.058-.426l2.336-.261a2.074,2.074,0,0,0,1.828-1.828L19.6,15.2a2.093,2.093,0,0,1,.453-1.072l1.484-1.841A2.066,2.066,0,0,0,21.536,9.705Z'
        transform='translate(0.01 0.01)'
        fill='#4cabfd'
        fill-rule='evenodd'
      />
      <path
        id='Union_6'
        data-name='Union 6'
        d='M4.359,10.09a1.091,1.091,0,0,0-1.514-1,1.087,1.087,0,0,0-.58.58,1.084,1.084,0,0,0-.086.424H0A3.254,3.254,0,0,1,.958,7.779a3.248,3.248,0,0,1,1.653-.891,3.278,3.278,0,0,1,3.37,1.374,3.316,3.316,0,0,1,.3.555,3.279,3.279,0,0,1,.257,1.273ZM2.611,6.482A3.261,3.261,0,0,1,.958,5.591a3.237,3.237,0,0,1-.7-1.039,3.266,3.266,0,0,1,0-2.545A3.278,3.278,0,0,1,2,.266,3.279,3.279,0,0,1,3.27.01h.037V0H6.463a2.1,2.1,0,0,1-.525,1.39l.042.061A3.238,3.238,0,0,1,6.472,2.62a3.26,3.26,0,0,1-.491,2.488A3.322,3.322,0,0,1,5.1,5.99a3.316,3.316,0,0,1-.555.3,3.287,3.287,0,0,1-1.931.19Zm.234-4.207a1.088,1.088,0,0,0-.665,1,1.088,1.088,0,0,0,.319.77,1.088,1.088,0,0,0,.771.319,1.091,1.091,0,0,0,1-1.514,1.087,1.087,0,0,0-.58-.58,1.1,1.1,0,0,0-.849,0Z'
        transform='translate(8.001 6)'
        fill='#fff'
      />
    </svg>
  );
};
const VerifiedSvg = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='22'
      viewBox='0 0 18 22'
    >
      <path
        id='Icon_material-verified-user'
        data-name='Icon material-verified-user'
        d='M13.5,1.5l-9,4v6c0,5.55,3.84,10.74,9,12,5.16-1.26,9-6.45,9-12v-6Zm-2,16-4-4,1.41-1.41,2.59,2.58,6.59-6.59L19.5,9.5Z'
        transform='translate(-4.5 -1.5)'
        fill='#14a81a'
      />
    </svg>
  );
};
const MennuSvg = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='4.5'
      height='18'
      viewBox='0 0 4.5 18'
    >
      <path
        id='Icon_metro-more-vert'
        data-name='Icon metro-more-vert'
        d='M17.674,12.212a2.25,2.25,0,1,0-2.25-2.25A2.249,2.249,0,0,0,17.674,12.212Zm0,2.25a2.25,2.25,0,1,0,2.25,2.25A2.249,2.249,0,0,0,17.674,14.462Zm0,6.75a2.25,2.25,0,1,0,2.25,2.25A2.249,2.249,0,0,0,17.674,21.212Z'
        transform='translate(-15.424 -7.712)'
        fill='#494949'
      />
    </svg>
  );
};
const AddSvg = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='29.25'
      height='29.25'
      viewBox='0 0 29.25 29.25'
    >
      <path
        id='Icon_ionic-ios-add-circle'
        data-name='Icon ionic-ios-add-circle'
        d='M18,3.375A14.625,14.625,0,1,0,32.625,18,14.623,14.623,0,0,0,18,3.375Zm6.363,15.75H19.125v5.238a1.125,1.125,0,0,1-2.25,0V19.125H11.637a1.125,1.125,0,0,1,0-2.25h5.238V11.637a1.125,1.125,0,0,1,2.25,0v5.238h5.238a1.125,1.125,0,0,1,0,2.25Z'
        transform='translate(-3.375 -3.375)'
        fill='#4cabfd'
      />
    </svg>
  );
};
const CrossBgSvg = () => {
  return (
    <svg

      xmlns='http://www.w3.org/2000/svg'
      width='29.25'
      height='29.25'
      viewBox='0 0 29.25 29.25'
    >
      <path
        id='Icon_ionic-ios-close-circle'
        data-name='Icon ionic-ios-close-circle'
        d='M18,3.375A14.625,14.625,0,1,0,32.625,18,14.623,14.623,0,0,0,18,3.375Zm3.705,19.92L18,19.589l-3.705,3.705a1.124,1.124,0,1,1-1.589-1.589L16.411,18l-3.705-3.705a1.124,1.124,0,0,1,1.589-1.589L18,16.411l3.705-3.705a1.124,1.124,0,1,1,1.589,1.589L19.589,18l3.705,3.705a1.129,1.129,0,0,1,0,1.589A1.116,1.116,0,0,1,21.705,23.295Z'
        transform='translate(-3.375 -3.375)'
        opacity='0.3'
      />
    </svg>
  );
};
const CamersSvg = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='36'
      height='31.5'
      viewBox='0 0 36 31.5'
    >
      <path
        id='Icon_awesome-camera'
        data-name='Icon awesome-camera'
        d='M36,10.125v20.25a3.376,3.376,0,0,1-3.375,3.375H3.375A3.376,3.376,0,0,1,0,30.375V10.125A3.376,3.376,0,0,1,3.375,6.75H9.563l.865-2.313A3.37,3.37,0,0,1,13.584,2.25h8.824a3.37,3.37,0,0,1,3.157,2.187l.872,2.313h6.188A3.376,3.376,0,0,1,36,10.125ZM26.438,20.25A8.438,8.438,0,1,0,18,28.688,8.444,8.444,0,0,0,26.438,20.25Zm-2.25,0A6.188,6.188,0,1,1,18,14.063,6.2,6.2,0,0,1,24.188,20.25Z'
        transform='translate(0 -2.25)'
        fill='#f5a041'
      />
    </svg>
  );
};
const CrossBGWhieSvg = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='29.25'
      height='29.25'
      viewBox='0 0 29.25 29.25'
    >
      <path
        id='Icon_ionic-ios-close-circle'
        data-name='Icon ionic-ios-close-circle'
        d='M18,3.375A14.625,14.625,0,1,0,32.625,18,14.623,14.623,0,0,0,18,3.375Zm3.705,19.92L18,19.589l-3.705,3.705a1.124,1.124,0,1,1-1.589-1.589L16.411,18l-3.705-3.705a1.124,1.124,0,0,1,1.589-1.589L18,16.411l3.705-3.705a1.124,1.124,0,1,1,1.589,1.589L19.589,18l3.705,3.705a1.129,1.129,0,0,1,0,1.589A1.116,1.116,0,0,1,21.705,23.295Z'
        transform='translate(-3.375 -3.375)'
        fill='#fff'
      />
    </svg>
  );
};

export {
  GuruzLogo,
  DashboardSvg,
  PeopleSvg,
  WistleSvg,
  MoneySvg,
  SettingsSvg,
  SearchSvg,
  ArrowDown,
  WistleDashboardSvg,
  PeopleDashboardSvg,
  MoneyDashboardSvg,
  StarSvg,
  FilterSvg,
  EyeSvg,
  GreenArrowUp,
  RedArrowDown,
  ArrowBackBg,
  MenuBGSvg,
  LocationSvg,
  ViolenSvg,
  GuruzSvg,
  VerifiedSvg,
  MennuSvg,
  AddSvg,
  CrossBgSvg,
  CamersSvg,
  CrossBGWhieSvg,
};
