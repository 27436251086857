import React, { useEffect, useState } from 'react';
import {
  AddSvg,
  CrossBgSvg,
  LocationSvg,
  MennuSvg,
  SearchSvg,
  StarSvg,
  ViolenSvg,
} from '../../../assets/svg';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import './index.css';
import { create_category, get_category, update_category } from '../../../API';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';

export default function ManageCategories() {
  const jwtToken = localStorage.getItem('jwtToken');
  const [state, setState] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false,
  });
  const [errMsg, setErrMsg] = useState('');
  const [searchTxt, setSearchTxt] = useState('');
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false)
  const [editCategory, setEditCategory] = useState(null)
  const [categoriesChange, setCategoriesChange] = useState(false);
  const filteredCategory = categories?.filter((e) =>
    e.title.toLowerCase().includes(searchTxt.toLowerCase())
  );
  const config = {
    headers: { Authorization: `Bearer ${jwtToken}` },
  };
  function getCategory() {
    setLoading(true)
    get_category(config)
      .then((res) => {
        if (res?.status === 200 && res?.data?.success) {
          setCategories(res.data.data);
          setLoading(false)
        } else if (res?.status === 401) {
          setLoading(false)
          setErrMsg(res?.data?.message);
        }
      })
      .catch((err) => {
        setLoading(false)
        console.log(err);
      });
  }

  useEffect(() => {
    getCategory();
  }, [categoriesChange]);

  // const createCategory = () => {

  //   create_category(config)
  //     .then((res) => {
  //       if (res?.status === 200 && res?.data?.success) {
  //         setCategories(res.data.data);
  //       } else if (res?.status === 401) {
  //         setErrMsg(res?.data?.message);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  return (
    <div className='selectedTab manageCategories transactions'>
      <div className='tabHeader'>
        <div className='tabTitle'>
          Created <span>{filteredCategory?.length} Categories</span>
        </div>
        <div className='search'>
          <div className='searchInput'>
            <SearchSvg />
            <input type='text'
              name='Search'
              value={searchTxt}
              onChange={(e) => setSearchTxt(e.target.value)}
              placeholder='Search' />
          </div>
          <div className='filter'>
            <button onClick={() => setState({ isPaneOpen: true })}>
              Add New Category
            </button>
          </div>
        </div>
      </div>
      <div className='tabBody' style={{
        minHeight: "200px"
      }}>
        <div className='requestCards'>
          {loading ?
            // [editCategory,setEditCategory]
            <ClipLoader size={30} color="black" />

            : <>
              {filteredCategory?.map((category) => (
                <CategoriesCard
                  key={category.title}
                  title={category.title}
                  setEditCategory={setEditCategory}
                  setState={()=> setState({ isPaneOpen: true })}
                  cat={category}
                  categories={category.subItems}
                />
              ))}
            </>}
        </div>
      </div>
      <AddCategorySlider editCategory={editCategory} setEditCategory={setEditCategory} setCategoriesChange={setCategoriesChange} state={state} setState={setState} />
    </div>
  );
}

const CategoriesCard = ({ id, title, categories, cat, setEditCategory, setState }) => {

  return (
    <div key={id} onClick={() => {
      setEditCategory(cat)
      setState()
    }} className='requestCard categoryCard'>
      <div className='requestCardBody'>
        <div className='txnRow categoryCardHeader'>
          <div className='title'>{title}</div>
          <div>
            <MennuSvg />
          </div>
        </div>
        <div className='categoties'>
          {categories?.map((category, i) => (
            <span key={`category-${i}`}>{category}</span>
          ))}
        </div>
      </div>
    </div>
  );
};

const AddCategorySlider = ({ state, editCategory, setEditCategory, setState, setCategoriesChange }) => {
  const jwtToken = localStorage.getItem('jwtToken')
  const [category, setCategory] = useState("")
  const [Subcategory, setSubCategory] = useState([])
  const [categoryLoading, setCategoryLoading] = useState(false)
 

  const config = {
    headers: { Authorization: `Bearer ${jwtToken}` },
  };

  useEffect(() => {

    if (editCategory){
      setCategory(editCategory.title)
      setSubCategory(editCategory.subItems)
    }
    return () => {
      setCategory("")
      setSubCategory([])
    }
  }, [editCategory])

 
  return (
    <SlidingPane
      className='requestSlider addCategorySlider'
      overlayClassName='requestSliderOverlay'
      isOpen={state.isPaneOpen}
      hideHeader
      width='30%'
      onRequestClose={() => {
        // triggered on "<" on left top click or on outside click
        setCategory("")
        setSubCategory([])
        setState({ isPaneOpen: false });
      }}
    >
      <div className='requestCardHeader'>
        <div className='cardTitle'>Add Category</div>
      </div>
      <div className='addCategoryBody'>
        <div className='title'>
          <input
            disabled={editCategory !== null}
            value={category}
            onChange={(e) => {
              setCategory(e.target.value)
            }} type='text' name='title' placeholder='Category Title' />
        </div>


        {/* <div

          onClick={async () => {

            try {
              let data = {
                title: category
              }
              if (category?.length > 0) {
                setCategoryLoading(true)
                await create_category(config, data)
                setCategoriesChange((val) => !val)
                setCategoryLoading(false)
                setState(false)
                toast.success('Category Added successFully', {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });

              } else {
                toast.error('Please Enter Valid Category Title', {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              }

            } catch (error) {
              setCategoryLoading(false)
              console.log(error)
              toast.error(error.response?.data?.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });

            }
          }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: 'none',
            gap: 10,
            borderRadius: '10px',
            padding: '10px 10px 10px 10px',
            margin: '30px 50px 0px 0px',
            backgroundColor: '#494949',
            outline: 'none',
            color: '#ffffff',
            cursor: 'pointer',
          }}  >
          <span >
            Add New Category
          </span>

          {categoryLoading && <ClipLoader size={15} color="white" />}
        </div> */}

        <div className='addSubCatrgory'>
          <div onClick={() => {
            setSubCategory([...Subcategory, ""])
          }} className='addSubCatrgoryHeader'>
            <AddSvg />
            <div>Add Category</div>
          </div>
          <div className='addSubCatrgorybody'>
            {Subcategory.map((e, index) => (
              <div key={`sub-cat-${index}`} className='subCategory'>
                <div className="rounded-input">
                  <input type="text" value={e} onChange={(e) => {
                    setSubCategory((prevInputs) => {
                      const updatedInputs = [...prevInputs];
                      updatedInputs[index] = e.target.value;
                      return updatedInputs;
                    });
                  }} placeholder="Enter Sub Category ..." />
                  <div onClick={() => {

                    setSubCategory((prevItems) => {
                      const updatedItems = prevItems.filter((_, i) => i !== index);
                      return updatedItems;
                    });

                  }}>
                    <CrossBgSvg
                    />
                  </div>

                </div>
                {/* <input className='title'>Guitar</input>
                <div className='remove'>
            
                </div> */}
              </div>
            ))}
          </div>


        </div>
        <div

          onClick={async () => {

            try {
              let data = {
                title: category,
                subItems:Subcategory
              }
              let error = false

              Subcategory.map((item) => {
                if (item === "") {
                  toast.error("Enter Valid Sub Category name ", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                  error = true
                  return

                }

              })

              if (error === true) {
                return
              }
              if (category?.length > 0) {
                setCategoryLoading(true)
                if (editCategory){
                 await  update_category(config, data)
                }else {
                  await create_category(config, data)
                }
            
                setCategoriesChange((val) => !val)
                setCategoryLoading(false)
                setState(false)
                toast.success(editCategory?'Category Updated successFully':'Category Added successFully', {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });

              } else {
                toast.error('Please Enter Valid Category Title', {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              }

            } catch (error) {
              setCategoryLoading(false)
              console.log(error)
              toast.error(error.response?.data?.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });

            }
          }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: 'none',
            gap: 10,
            borderRadius: '10px',
            padding: '10px 10px 10px 10px',
            margin: '30px 50px 0px 0px',
            backgroundColor: '#494949',
            outline: 'none',
            color: '#ffffff',
            cursor: 'pointer',
          }}  >
          <span >
           {editCategory!==null?"Edit Category" :"Add New Category"}
          </span>

          {categoryLoading && <ClipLoader size={15} color="white" />}
        </div>
      </div>
    </SlidingPane>
  );
};
