
export const NumberComponent = (number=0) => {
    
    const formattedNumber = number >= 1000 ? (number / 1000).toFixed(0) + "k" : number.toString();
  
    return formattedNumber;
  };


  export const convertedNumTOTime =(num)=>{
    
    const timeString = num.toString().padStart(4, '0');
    const dateObj = new Date(`01/01/2000 ${timeString.substr(0, 2)}:${timeString.substr(2)}`);
    const timeFormatted = dateObj.toLocaleTimeString('en-US', { hour12: true, hour: 'numeric', minute: 'numeric' });
    
     return timeFormatted
}